import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MobileHeaderComponent } from '../shared/mobile-header/mobile-header.component';
import { MobileFooterComponent } from "../shared/mobile-footer/mobile-footer.component";

@Component({
  selector: 'app-mobile',
  standalone: true,
  imports: [RouterOutlet, MobileHeaderComponent, MobileFooterComponent],
  templateUrl: './mobile.component.html',
  styleUrl: './mobile.component.scss'
})
export class MobileComponent {
  constructor(){
    console.log('test--mobile')
  }
}
