import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class  MobileGuard implements CanActivate {
isResposive = false;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,private breakpointObserver: BreakpointObserver,private router: Router) {
    this.breakpointObserver.observe([
      "(max-width: 768px)"
    ]).subscribe((result: BreakpointState) => {
      console.log('result---',result)
      this.isResposive = result.matches;
   
    });
  }

  canActivate() {
    // console.log('window.innerWidth--',window.innerWidth)
    if (isPlatformBrowser(this.platformId)) {
      
    if (!this.isResposive) {
      this.router.navigate(['/']);
      return false;
    }

    return true;
  }
  return false
}
}

@Injectable()
export class DesktopGuard implements CanActivate {
  isResposive = false;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,private breakpointObserver: BreakpointObserver,private router: Router) {
    this.breakpointObserver.observe([
      "(max-width: 768px)"
    ]).subscribe((result: BreakpointState) => {
      console.log('result---',result)
      this.isResposive = result.matches;
   
    });
  }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    
    if (isPlatformBrowser(this.platformId)) {
      console.log('window.innerWidth--',window.innerWidth)
    if ( this.isResposive ) {
      this.router.navigate(['/m/']);
      return false;
    }

    return true;
  }
  return false
}

}
