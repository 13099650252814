<div class="z-index">
<div class="header-top maintain-ratio">
    <span class="color-white font-12 font-position text-center">Your Dream <span class="font-weight-bold">Wedding</span>, Perfectly <span class="font-weight-bold">Planned!</span></span>
    <p-dropdown 
    [options]="cities" 
    [(ngModel)]="selectedCity" 
    optionLabel="name" 
    placeholder="Select a City"
    class="position-7"
    dataKey="code"
    optionValue = "code"
    (onChange)="citySelection()"
    >
    <ng-template pTemplate="dropdownicon">
        <img alt="dropdown icon" src="./assets/dropdown.svg">
    </ng-template></p-dropdown>
    <!-- <img src="./assets/dropdown.svg" height="20" alt="logo"> -->

    <div class="float-right postioning-app">
        
        <img src="./assets/GooglePlay.png" class="inline" height="20" alt="logo">
        <span class="color-white font-12 font-position inline" > Download App</span>
        
    </div>

</div>
<div class="header-2  maintain-ratio">
    <div class="d-flex">
        <div class="w-10vw top-2 position-relative">
            <!-- <img src="./assets/logo.png" class="position-relative bottom-1" height="20" alt="logo"> -->
            <img src="./assets/Evershine Logo.svg" class="position-relative " height="20" alt="logo">
            <!-- <span class="color-white  font-position text-center-evershine fonts  font-16 left-4">Evershine Events</span> -->
        </div>
       
    <!-- <div class="collapse navbar-collapse d-flex  " id="navbarNav">
        <ul class="navbar-nav flex-row">
            <li class="nav-item" *ngFor="let item of navBar; let i = index" (click)="selectItem(i)"  [ngClass]="{'active': selectedItem === i}">
              <a class="nav-link text-white"  >{{item}}</a>
            </li>  
    
          </ul>
          </div> -->

          <div class="collapse navbar-collapse d-flex" id="navbarNav">
            <ul class="navbar-nav flex-row">
              <li
                class="nav-item dropdown"
                *ngFor="let item of navBar; let i = index"
                [ngClass]="{'active': activeIndex  === i, 'show': selectedItem === i}"
                
              >
                <!-- Top-level menu -->
                <a
                  class="nav-link text-white"
                  [class.dropdown-toggle]="item.subMenu.length > 0"
                  (click)="toggleDropdown(i, $event,item)"
                >
                  {{ item.label }} 
                </a>
          
                <!-- Dropdown menu -->
                <ul
                  class="dropdown-menu"
                  [ngClass]="{'show': selectedItem === i}"
                  *ngIf="item.subMenu.length > 0"
                >
                  <li
                    *ngFor="let subItem of item.subMenu"
                    class="dropdown-subitem"
                    (click)="$event.stopPropagation()"
                  >
                    <a class="dropdown-item" >{{ subItem.label }}</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          


          <div class="center">
            <span class="color-white  positions   text-decoration-underline">Not a member? <span>&nbsp;<b> Register</b></span> <p-button label="Log In" /></span>
            
          </div>


    </div>
    

</div>
</div>