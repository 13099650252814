<footer class=" ">
    <div class="col-md-12 d-block padding-footer-mobile">
        <!-- <p-menubar [model]="itemsNav" /> -->
        <div class="get-in">
            Inspiration for your wedding
        </div>
        <p-carousel 
        [value]="navBar" 
        [numVisible]="1" 
        [numScroll]="7" 
        [circular]="false" 
        [showIndicators]="false"
        [responsiveOptions]="responsiveOptions">
            <ng-template let-product pTemplate="product" let-idx="index" class="'nav-item'">
                <!-- {{product}} -->
                <ul class="navbar-nav flex-row">
                    <li class="nav-item"  (click)="selectItem(transform(navBar,product))"
                        [ngClass]="{'active': selectedItem === transform(navBar,product)}">
                        <a class="nav-link text-black">{{product }}</a>
                    </li>
    
                </ul>
    
                
            </ng-template>
    </p-carousel>
        <!-- <button (click)="scrollRight()">Scroll Right</button> -->
        <div class="black-line d-block col-md-12"></div>

        <div class="marg-top ">
            <div class="d-flex flex-wrap">
                <ng-container *ngFor="let item of footertext | slice:0:(more ? undefined : 5)">
                    <div class="wid-14">
                        <span class="font-bold">{{item.heading}}</span> <br />
                        <span class="photo-copyright">{{item.subHeading}}</span>
                    </div>
                </ng-container>
                <span (click)="more = !more" class="more">{{ more ? 'less':'Show More' }} <i class="pi pi-angle-down" style="font-size: 0.825rem"></i></span>
            </div>
            
        </div>
        <div class="gray-line col-md-12"></div>
        <div class="addr col-md-12 pl-0">
            <ul class="pl-0 pt-4">
                <li class="list-heading">Start Planning</li>
                <li class="letter-spacing">Search by Vendor</li>
                <li class="letter-spacing">Search by City</li>
                <li class="letter-spacing">Download our App</li>
                <li class="letter-spacing">Top Rated Vendor</li>
                <li class="letter-spacing">Destination weeding</li>
            </ul>
        </div>
        <div class="addr col-md-12 pl-0">
            <ul class="pl-0 pt-4">
                <li class="list-heading">Wedding Ideas</li>
                <li class="letter-spacing">Wedding Blogs</li>
                <li class="letter-spacing">Wedding Inspiration Gallery</li>
                <li class="letter-spacing">Real Wedding</li>
                <li class="letter-spacing">Submit Wedding</li>
            </ul>
        </div>
        <div class="addr col-md-12 pl-0">
            <ul class="pl-0 pt-4">
                <li class="list-heading">Photo Gallery</li>
                <li class="letter-spacing">Search by Vendor</li>
                <li class="letter-spacing">Search by City</li>
                <li class="letter-spacing">Download our App</li>
                <li class="letter-spacing">Top Rated Vendor</li>
                <li class="letter-spacing">Destination weeding</li>
                <li class="letter-spacing">Search by Vendor</li>
                <li class="letter-spacing">Search by City</li>
                <li class="letter-spacing">Download our App</li>
            </ul>
        </div>
        <div class="gray-line col-md-12"></div>
        <div class="float-right col-md-12 pl-0 pt-4">
            <div class="get-in mb-2">
                Get in the Know
            </div>
            <div>
                <input type="text" class="email-input" placeholder="Enter your email" pInputText />
                <p-button label="Submit" styleClass="grad-button" />
                <!-- <p-button label="Submit Wedding" styleClass="grad-button-wedd" />
                <p-button label="Register as a Vendor" styleClass="vendor-button" /> -->
            </div>
        </div>
        <div class="gray-line col-md-12 d-flex mt-4"></div>
        <div class="footer-dimensions col-md-12 pl-0 pt-1">


            <div class="addr-new ">
                <img src="./assets/logo-footer.svg" class="position-relative mb-2" height="25" alt="logo">
                WeWork DLF Two
                Horizon Centre, DLF Phase 5, Sector 43, Golf Course Road, Gurugram, Haryana 122002 <span><span class="font-weight-bold">Contact:</span> +91
                9560085362, +91 8373963332, <span class="font-weight-bold">Email:</span> Hello&#64;evershineevents.com</span>
            </div>
            <div class="d-flex  pl-0 mt-3 col-md-12 pr-0">
            <p-button label="Submit Wedding" styleClass="grad-button-wedd col-md-6" />
                <p-button label="Register as a Vendor" styleClass="vendor-button col-md-6" />
            </div>
            </div>
            <div class="gray-line col-md-12 d-flex mt-4"></div>
            <div class="footer-dimensions col-md-12  pl-0">
                <div class="photo-copyright ">
                    Photo copyright retained by photo owners, everything else © 2023 Evershine Events® 
                    Investor Relations | <span class="red-text">Privacy Policy </span>| <span class="red-text">Terms of Service </span> | <span class="red-text">Policy</span> 
                </div>
                <div class="social-position mt-3">
                    <ul class="social-icon pl-0" #scrollList>
                        <li class="social-list pl-0"><img src="./assets/Insta Icon.svg" class="position-relative " height="20" alt="logo"></li>
                        <li class="social-list pl-0"><img src="./assets/Face book Icon.svg" class="position-relative " height="20" alt="logo"></li>
                        <li class="social-list pl-0"><img src="./assets/Twitter Icon.svg" class="position-relative " height="20" alt="logo"></li>
                        <li class="social-list pl-0"><img src="./assets/Linkedin Icon.svg" class="position-relative " height="20" alt="logo"></li>
                        <li class="social-list pl-0"><img src="./assets/Youtube Icon.svg" class="position-relative " height="20" alt="logo"></li>
                      </ul>  
                </div>
                </div> 
 
    </div>
    <div class="bottom-strip"></div>
</footer>