import { Component, Input, SimpleChanges } from '@angular/core';
import { InputIconModule } from 'primeng/inputicon';
import { IconFieldModule } from 'primeng/iconfield';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DividerModule } from 'primeng/divider';
import { NgxSliderModule ,Options  } from '@angular-slider/ngx-slider';
@Component({
  selector: 'app-common-filter',
  standalone: true,
  imports: [InputIconModule, IconFieldModule, InputTextModule, FormsModule, CommonModule,DividerModule,NgxSliderModule],
  templateUrl: './common-filter.component.html',
  styleUrl: './common-filter.component.scss'
})
export class CommonFilterComponent {
  options: string[] = [
    '5 star Hotel',
    '4 star Hotel',
    'Banquet Hall',
    'Marriage Garden',
    'Destination',
    'Wedding Resort'
  ];
  @Input() filter1!:string[];
  minValue: number = 800; // Default minimum value
  maxValue: number = 3000; // Default maximum value
  selectedMin: number = 800; // Selected minimum
  selectedMax: number = 3000; // Selected maximum

  sliderOptions: Options = {
    floor: 500,
    ceil: 5000,
    step: 100,
    translate: (value: number): string => `₹${value}`,
    getSelectionBarColor: (value: number): string => '#FF5A79', // Selected range color
  };

  // Histogram mock data
  histogramData: number[] = [
    5, 10, 15, 30, 50, 80, 70, 50, 30,50, 80, 70, 50, 30,50, 80, 70, 50, 30, 20, 15, 10, 5, 2, 1, 0, 0,
  ];
  selectedOptions: string[] = []; // To track selected buttons
  
  toggleOption(option: string): void {
    if (this.isSelected(option)) {
      // Remove the option if already selected
      this.selectedOptions = this.selectedOptions.filter(o => o !== option);
    } else {
      // Add the option if not selected
      this.selectedOptions.push(option);
    }
  }
  ngOnInit(){
    console.log('filter1---',this.filter1)
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['filter1']) {
      console.log('Data updated this.filter1:', this.filter1);
      // Additional logic here if needed
    }
  }
  isSelected(option: string): boolean {
    return this.selectedOptions.includes(option);
  }

  get selectedMinIndex(): number {
    return Math.floor(
      ((this.selectedMin - this.sliderOptions.floor!) /
        (this.sliderOptions.ceil! - this.sliderOptions.floor!)) *
        (this.histogramData.length - 1)
    );
  }

  get selectedMaxIndex(): number {
    return Math.ceil(
      ((this.selectedMax - this.sliderOptions.floor!) /
        (this.sliderOptions.ceil! - this.sliderOptions.floor!)) *
        (this.histogramData.length - 1)
    );
  }
}
