<div class="header-top-mobile margins">
    <div class="d-flex">
    <div class="">
        <img src="./assets/Group 10516.svg" class="position-relative height" height="20" alt="logo">
    </div>
    <div class="ml-auto center" (click)="sidebarVisible2 = true">
        <img src="./assets/menu.svg" class="position-relative "   height="20" alt="logo">
    </div>
</div>
<p-sidebar [(visible)]="sidebarVisible2" position="right">
    <ng-template pTemplate="header">
        <div class="">
            <img src="./assets/red-logo.svg" class="position-relative height" height="20" alt="logo">
        </div>
    
    </ng-template>
    <p-toast />
   
    <p-card header="">
        <p-avatar image="./assets/name.png" class="mr-2" shape="circle" />
        <div class="d-flex">
        <p class="m-0 name">
            Shaheen Shaikh
        </p>
        <p class="m-0 edit">
            Edit Profile
        </p>
    </div>
    </p-card>
    <!-- <ng-template pTemplate="end">
        <button pRipple class="relative overflow-hidden w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
            <p-avatar image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png" class="mr-2" shape="circle" />
            <span class="inline-flex flex-column">
                <span class="font-bold">Amy Elsner</span>
                <span class="text-sm">Admin</span>
            </span>
        </button>
    </ng-template> -->
    <p-panelMenu [model]="items" styleClass="w-full md:w-20rem" />
    <div class="footer-social">
        <div class="social-position">
            <ul class="social-icon">
                <li class="social-list pl-0"><img src="./assets/Insta.svg" class="position-relative " height="15" alt="logo"></li>
                <li class="social-list pl-0"><img src="./assets/Facebook.svg" class="position-relative " height="15" alt="logo"></li>
                <li class="social-list pl-0"><img src="./assets/Twitter.svg" class="position-relative " height="15" alt="logo"></li>
                <li class="social-list pl-0"><img src="./assets/Linkedin.svg" class="position-relative " height="15" alt="logo"></li>
                <li class="social-list pl-0"><img src="./assets/Youtube.svg" class="position-relative " height="15" alt="logo"></li>
                <li class="social-list pl-0 copy-right"> <span>© 2023 Evershine Events</span></li>
              </ul>  
  
        </div>
    </div>
</p-sidebar>

</div>