import { Component, inject } from '@angular/core';
import { CommonFilterComponent } from '../../../shared/common-filter/common-filter.component';
import { MenuItem } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NgxSearchFilterModule } from 'ngx-search-filter';
import { ButtonModule } from 'primeng/button';
import { ComponentDataService } from '../../../shared/component-data.service';
import { FilterPipeModule } from 'ngx-filter-pipe';
@Component({
  selector: 'app-venue-list',
  standalone: true,
  imports: [CommonFilterComponent,BreadcrumbModule, RouterModule,CommonModule,RadioButtonModule,FormsModule,NgxSearchFilterModule,ButtonModule,FilterPipeModule],
  templateUrl: './venue-list.component.html',
  styleUrl: './venue-list.component.scss'
})
export class VenueListComponent {
  items: MenuItem[] | undefined;

  home: MenuItem | undefined;
  venues: any = [];
  httpClient = inject(HttpClient);
  filter1: string[] = [];
   
  selectedCity = '';
  heartColor=true;
  city = '';
  cityCode = ''
  defaultImg = 'https://storage.googleapis.com/winged-tenure-439711-c4.appspot.com/Evershine%20Properties%20Listing%20database/%24Default%20Images/EP-DOUTRE-HLT-AGR-001.png';

  size: number = 50;
  isLiked: boolean = false;
    selectedCountry: any;

    filteredCountries: any[] =[];


    venues1 = [
      {
        name: 'The Oberoi Amarvilas',
        location: 'Sector 15',
        price: '₹2000/plate',
        image: 'assets/oberoi.jpg'
      },
      {
        name: 'The Radisson',
        location: 'Sector 35',
        price: '₹1800/plate',
        image: 'assets/radisson.jpg'
      },
      {
        name: 'Double Tree by Hilton',
        location: 'Sector 40',
        price: '₹1500/plate',
        image: 'assets/doubletree.jpg'
      }
      // Add more venues as needed
    ];

  options = [
    {
      label: 'All',
      value: 'all',
      image: './assets/all.png', // Replace with your image URL
    },
    {
      label: 'Agra',
      value: 'agra',
      image: './assets/agra.png', // Replace with your image URL
    },
    {
      label: 'New Delhi',
      value: 'delhi',
      image: './assets/delhi.png', // Replace with your image URL
    },{
      label: 'Noida',
      value: 'noida',
      image: './assets/noida.png', // Replace with your image URL
    },{
      label: 'Gurgaon',
      value: 'gurgaon',
      image: './assets/gurgaon.png', // Replace with your image URL
    },{
      label: 'Goa',
      value: 'goa',
      image: './assets/goa.png', // Replace with your image URL
    },{
      label: 'Jaipur',
      value: 'jaipur',
      image: './assets/jaipur.png', // Replace with your image URL
    },{
      label: 'Udaipur',
      value: 'udaipur',
      image: './assets/udaipur.png', // Replace with your image URL
    },{
      label: 'Chandigarh',
      value: 'changdigarh',
      image: './assets/chandigarh.png', // Replace with your image URL
    },{
      label: 'Jim Corbett',
      value: 'corbett',
      image: './assets/jim corbett.png', // Replace with your image URL
    },
  ];
  selectedOption: string = '';
  constructor(private commonData:ComponentDataService ) {
    
  }
  ngOnInit() {
      this.items = [{ label: 'Home', route: '/home' }, { label: 'vendors' }, { label: 'Wedding Venues' }, { label: 'Wedding Venues Gurgaon', route: '/inputtext' }];
      this.commonData.currentMessage.subscribe(message=> {
        if(message !== 'service'){
            this.selectedCity = message;
        }
        else{
            if (localStorage.getItem("cityName") !== null) {
                this.selectedCity = localStorage.getItem('cityName') || '';
              }
        }
        console.log('selectedcityt---',this.selectedCity)
    })
      this.sheetApi();
  }

  sheetApi(){
    const sheetName ='Venues (All)';
    const url = `${environment.sheet_url}${environment.sheet_id}/values/${sheetName}?key=${environment.sheet_api_key}`;
    this.httpClient.get(url, {
       
    }).subscribe((response:any): void => {
       console.log('cities--',response);
       let arrr = response.values.map((x:any) =>({
        property_name:x[1],
        EPID:x[2],
        property_type:x[3],
        city:x[4],
        area:x[5],
        pax:x[11],
        rooms:x[13],
        banquest:x[12],
        blue_tick:x[22],
        img:`${environment.imgPath}Evershine Properties Listing database${this.city}/${x[2]}/${x[2]}-001`,
        veg:x[7],
        nonVeg:x[8]
        
      }))
    
      this.venues = arrr;
      this.venues = this.venues.filter((val:any)=> val.city !== 'City')
      response.values.forEach((element:any) => {
        this.filter1.push(element[3] as string)

        // console.log('element---',element)
      });
      console.log('this.venues123--',this.venues)
      
      this.filter1.splice(0, 1);
      this.filter1 = [...new Set(this.filter1)];
      this.filter1 = this.filter1.filter(str => str !== "");
      console.log('filter1---',this.filter1)
    }); 
    }
}
