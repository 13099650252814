import { CommonModule } from '@angular/common';
import { Component, ElementRef, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenubarModule } from 'primeng/menubar';
import { CarouselModule } from 'primeng/carousel';
@Component({
  selector: 'app-mobile-footer',
  standalone: true,
  imports: [CommonModule,ButtonModule,TabMenuModule,MenubarModule,CarouselModule],
  templateUrl: './mobile-footer.component.html',
  styleUrl: './mobile-footer.component.scss'
})
export class MobileFooterComponent {
  itemsNav: MenuItem[] | undefined;
  responsiveOptions: any[] | undefined;
  selectedCity = 'New York';
  navBar = ['Popular','Venues','Bridal Makeup', 'Photography & Videography','Wedding Planners', 'Decor & Floral Design', 'DJ & Sound', 'Dance & Choreography', 'Entertainment & Performers','Catering & Dining']
 footertext=[{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'}]
  selectedItem: number | null = 1;
  items: MenuItem[] | undefined;
  @ViewChild('scrollList', { static: false }) scrollList!: ElementRef;
  more = false;
  constructor() {
  
  }
  
  ngOnInit() {
    this.itemsNav = [
      {
          label: 'Home',
          icon: 'pi pi-home'
      },
      {
          label: 'Features',
          icon: 'pi pi-star'
      },
      {
          label: 'Projects',
          icon: 'pi pi-search',
          items: [
              {
                  label: 'Components',
                  icon: 'pi pi-bolt'
              },
              {
                  label: 'Blocks',
                  icon: 'pi pi-server'
              },
              {
                  label: 'UI Kit',
                  icon: 'pi pi-pencil'
              },
              {
                  label: 'Templates',
                  icon: 'pi pi-palette',
                  items: [
                      {
                          label: 'Apollo',
                          icon: 'pi pi-palette'
                      },
                      {
                          label: 'Ultima',
                          icon: 'pi pi-palette'
                      }
                  ]
              }
          ]
      },
      {
          label: 'Contact',
          icon: 'pi pi-envelope'
      },
      {
          label: 'Contact',
          icon: 'pi pi-envelope'
      },
      {
          label: 'Contact',
          icon: 'pi pi-envelope'
      },
      {
          label: 'Contact',
          icon: 'pi pi-envelope'
      },
      {
          label: 'Contact',
          icon: 'pi pi-envelope'
      },
      {
          label: 'Contact',
          icon: 'pi pi-envelope'
      },
      {
          label: 'Contact',
          icon: 'pi pi-envelope'
      },
      {
          label: 'Contact',
          icon: 'pi pi-envelope'
      },
      {
          label: 'Contact',
          icon: 'pi pi-envelope'
      },
      {
          label: 'Contact',
          icon: 'pi pi-envelope'
      },
      {
          label: 'Contact',
          icon: 'pi pi-envelope'
      },
      {
          label: 'Contact',
          icon: 'pi pi-envelope'
      },
      {
          label: 'Contact',
          icon: 'pi pi-envelope'
      },
      {
          label: 'Contact',
          icon: 'pi pi-envelope'
      },
      {
          label: 'Contact',
          icon: 'pi pi-envelope'
      },
      {
          label: 'Contact',
          icon: 'pi pi-envelope'
      },
      {
          label: 'Contact',
          icon: 'pi pi-envelope'
      },
      {
          label: 'Contact',
          icon: 'pi pi-envelope'
      },
      {
          label: 'Contact',
          icon: 'pi pi-envelope'
      },
      {
          label: 'Contact',
          icon: 'pi pi-envelope'
      },
      {
          label: 'Contact',
          icon: 'pi pi-envelope'
      },
      {
          label: 'Contact',
          icon: 'pi pi-envelope'
      }
  ]
//  console.log(window.innerWidth)
    this.items = [
      { label: 'Dashboard', icon: 'pi pi-home' },
      { label: 'Transactions', icon: 'pi pi-chart-line' },
      { label: 'Products', icon: 'pi pi-list' },
      { label: 'Messages', icon: 'pi pi-inbox' }
  ]
    this.selectedCity = 'NY';
    this.responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '991px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 4,
            numScroll: 2
        }
    ];
}

selectItem(index: number) {
  this.selectedItem = index;
}
scrollRight() {
    
    this.scrollList.nativeElement.scrollBy({
        top: 0,
        left: 100, // Change this value for scroll amount
        behavior: 'smooth'
    });
}
transform(items:any[] , item:any): any {
    return items.indexOf(item);
  }
}
