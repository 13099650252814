import { Component } from '@angular/core';
import { GalleriaModule } from 'primeng/galleria';
import { InputIconModule } from 'primeng/inputicon';
import { IconFieldModule } from 'primeng/iconfield';
import { InputTextModule } from 'primeng/inputtext';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { CarouselModule } from 'primeng/carousel';
import { ButtonModule } from 'primeng/button';
import { TagModule } from 'primeng/tag';
import { CardModule } from 'primeng/card';
import { ImageModule } from 'primeng/image';
import {ReactiveFormsModule} from '@angular/forms';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AccordionModule } from 'primeng/accordion';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { SplitterModule } from 'primeng/splitter';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-mobile-home',
  standalone: true,
  imports: [GalleriaModule,InputIconModule,IconFieldModule,SplitterModule,DialogModule,InputTextModule,AutoCompleteModule,FormsModule,CarouselModule, ButtonModule, TagModule,CardModule,ImageModule,ReactiveFormsModule,RadioButtonModule,AccordionModule,TooltipModule],
  templateUrl: './mobile-home.component.html',
  styleUrl: './mobile-home.component.scss'
})
export class MobileHomeComponent {
  images: any[] | undefined;
  visible: boolean = false;
  position: string = 'bottom';
  public scale: number = 1;
  public isOn: boolean = false;
  public isAnimate: boolean = false;
  showIndicatorsOnItem: boolean = false;
  products:  any[] = [];
  heartColor=true;
  responsiveOptions: any[] = [
    {
        breakpoint: '1024px',
        numVisible: 5
    },
    {
        breakpoint: '768px',
        numVisible: 3
    },
    {
        breakpoint: '560px',
        numVisible: 1
    }
];
selectedCountry: any;
countries: any[] | undefined;
    filteredCountries: any[] =[];
  ngOnInit() {
    this.countries = [{
      "name": "Afghanistan",
      "code": "AF"
  },
  {
      "name": "Afghanistan",
      "code": "AF"
  }];
    // this.sheetApi()
      // this.scale = Math.round(this.size / 50 * 100) / 100;
        // this.photoService.getImages().then((images) => {
            this.images = [
              {
                  itemImageSrc: './assets/banner-small.svg',
                  thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria1s.jpg',
                  alt: 'Description for Image 1',
                  title: 'Title 1'
              },
              {
                  itemImageSrc: './assets/banner-home-2.svg',
                  thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria2s.jpg',
                  alt: 'Description for Image 2',
                  title: 'Title 2'
              },
              {
                  itemImageSrc: './assets/banner-home-3.svg',
                  thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria3s.jpg',
                  alt: 'Description for Image 3',
                  title: 'Title 3'
              }
          ];
  
          // this.countries = [{
          //     "name": "Afghanistan",
          //     "code": "AF"
          // },
          // {
          //     "name": "Afghanistan",
          //     "code": "AF"
          // }];
  
          this.products = [{
              id: '1000',
              code: 'f230fh0g3',
              name: 'Bamboo Watch',
              description: 'Product Description',
              image: './assets/radisson-hotel-agra.svg',
              price: 65,
              category: 'Accessories',
              quantity: 24,
              inventoryStatus: 'INSTOCK',
              rating: 5,
              city:'Gurgaon'
          },
          {
              id: '1000',
              code: 'f230fh0g3',
              name: 'Bamboo Watch',
              description: 'Product Description',
              image: './assets/radison-2.svg',
              price: 65,
              category: 'Accessories',
              quantity: 24,
              inventoryStatus: 'INSTOCK',
              rating: 5,
              city: 'Banglore'
          },{
              id: '1000',
              code: 'f230fh0g3',
              name: 'Bamboo Watch',
              description: 'Product Description',
              image: './assets/hilton-agra.svg',
              price: 65,
              category: 'Accessories',
              quantity: 24,
              inventoryStatus: 'INSTOCK',
              rating: 5,
              city:'Chennai'
          },{
              id: '1000',
              code: 'f230fh0g3',
              name: 'Bamboo Watch',
              description: 'Product Description',
              image: './assets/hilton-agra.svg',
              price: 65,
              category: 'Accessories',
              quantity: 24,
              inventoryStatus: 'INSTOCK',
              rating: 5,
              city:'Bhopal'
          },{
              id: '1000',
              code: 'f230fh0g3',
              name: 'Bamboo Watch',
              description: 'Product Description',
              image: './assets/hilton-agra.svg',
              price: 65,
              category: 'Accessories',
              quantity: 24,
              inventoryStatus: 'INSTOCK',
              rating: 5
          },{
              id: '1000',
              code: 'f230fh0g3',
              name: 'Bamboo Watch',
              description: 'Product Description',
              image: './assets/hilton-agra.svg',
              price: 65,
              category: 'Accessories',
              quantity: 24,
              inventoryStatus: 'INSTOCK',
              rating: 5
          },{
              id: '1000',
              code: 'f230fh0g3',
              name: 'Bamboo Watch',
              description: 'Product Description',
              image: './assets/hilton-agra.svg',
              price: 65,
              category: 'Accessories',
              quantity: 24,
              inventoryStatus: 'INSTOCK',
              rating: 5
          }
        ];
        // });
    }
    filterCountry(event: AutoCompleteCompleteEvent) {
      let filtered: any[] = [];
      let query = event.query;
  
      for (let i = 0; i < (this.countries as any[]).length; i++) {
          let country = (this.countries as any[])[i];
          if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
              filtered.push(country);
          }
      }
  
      this.filteredCountries = filtered;
  }
}
