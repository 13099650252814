import { Component, inject } from '@angular/core';
import { GalleriaModule } from 'primeng/galleria';
import { InputIconModule } from 'primeng/inputicon';
import { IconFieldModule } from 'primeng/iconfield';
import { InputTextModule } from 'primeng/inputtext';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { CarouselModule } from 'primeng/carousel';
import { ButtonModule } from 'primeng/button';
import { TagModule } from 'primeng/tag';
import { CardModule } from 'primeng/card';
import { ImageModule } from 'primeng/image';
import {ReactiveFormsModule} from '@angular/forms';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AccordionModule } from 'primeng/accordion';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { SplitterModule } from 'primeng/splitter';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ComponentDataService } from '../../../shared/component-data.service';
import { NgxSearchFilterModule } from 'ngx-search-filter';
import { NgOptimizedImage } from '@angular/common';
@Component({
  selector: 'app-desktop-home',
  standalone: true,
  imports: [GalleriaModule,InputIconModule,NgOptimizedImage,IconFieldModule,NgxSearchFilterModule,SplitterModule,DialogModule,InputTextModule,AutoCompleteModule,FormsModule,CarouselModule, ButtonModule, TagModule,CardModule,ImageModule,ReactiveFormsModule,RadioButtonModule,AccordionModule,TooltipModule],
  templateUrl: './desktop-home.component.html',
  styleUrl: './desktop-home.component.scss',
  providers: []
})
export class DesktopHomeComponent {
  images: any[] | undefined;
  visible: boolean = false;
  position: string = 'bottom';
  public scale: number = 1;
  public isOn: boolean = false;
  public isAnimate: boolean = false;
  showIndicatorsOnItem: boolean = false;
  countries: any[] | undefined;
  products:  any[] = [];
  httpClient = inject(HttpClient);
  city = '';
  cityCode = ''
  content = "Hiring foreign employees is a lengthy process in Dubai. HR services Dubai can fully assist in obtaining work visas and permits for your employees. They can guide you through the complex legal requirements and immigration procedures. With their years of expertise, HR consultants will not only save you time and effort but will also help you achieve your hiring goals more efficiently.Completing the necessary paperwork will become hassle-free with the TASC HR service provider in Dubai. We act as intermediaries between employers, employees, and government authorities responsible for issuing work visas. Our team maintains regular communication with the concerned authorities to expedite the processing of applications and address any issues that may arise."
  tabs = [
    { title: 'Can HR consultants assist in the process of obtaining work visas and permits for employees in India?', content: this.content },
    { title: 'Can HR consultants assist in the process of obtaining work visas and permits for employees in India?', content: this.content },
    { title: 'Can HR consultants assist in the process of obtaining work visas and permits for employees in India?', content: this.content },
    { title: 'Can HR consultants assist in the process of obtaining work visas and permits for employees in India?', content: this.content },
    { title: 'Can HR consultants assist in the process of obtaining work visas and permits for employees in India?', content: this.content },
    { title: 'Can HR consultants assist in the process of obtaining work visas and permits for employees in India?', content: this.content },
    { title: 'Can HR consultants assist in the process of obtaining work visas and permits for employees in India?', content: this.content }
];
  heartColor=true;
  myform: FormGroup | undefined;
  profileForm = new FormGroup({
    name: new FormControl(''),
    phone:new FormControl(''),
    email: new FormControl(''),
    date: new FormControl(''),
    guests: new FormControl(''),
    rooms: new FormControl(''),
    function: new FormControl('prewed'),
    functionTime: new FormControl('eve')

  });
  defaultImg = 'https://storage.googleapis.com/winged-tenure-439711-c4.appspot.com/Evershine%20Properties%20Listing%20database/%24Default%20Images/EP-DOUTRE-HLT-AGR-001.png';

  size: number = 50;
  isLiked: boolean = false;
    selectedCountry: any;

    filteredCountries: any[] =[];
    name = new FormControl('');

  positionOptions = [
      {
          label: 'Bottom',
          value: 'bottom'
      },
      {
          label: 'Top',
          value: 'top'
      },
      {
          label: 'Left',
          value: 'left'
      },
      {
          label: 'Right',
          value: 'right'
      }
  ];

  responsiveOptions: any[] = [
      {
          breakpoint: '1024px',
          numVisible: 5
      },
      {
          breakpoint: '768px',
          numVisible: 3
      },
      {
          breakpoint: '560px',
          numVisible: 1
      }
  ];
    prevSrc: any = '';
    prevDesc: any = '';
    venues: any = [];
    totalLikes = 0;
    iLike = false;
    selectedCity = ''
  constructor(private commonData:ComponentDataService ) {
    
  }
  ngOnInit() {
    this.commonData.currentMessage.subscribe(message=> {
        if(message !== 'service'){
            this.selectedCity = message;
        }
        else{
            if (localStorage.getItem("cityName") !== null) {
                this.selectedCity = localStorage.getItem('cityName') || '';
              }
        }
        console.log('selectedcityt---',this.selectedCity)
    })
    this.sheetApi()
      this.scale = Math.round(this.size / 50 * 100) / 100;
        // this.photoService.getImages().then((images) => {
            this.images = [
              {
                  itemImageSrc: './assets/banner-home.svg',
                  thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria1s.jpg',
                  alt: 'Description for Image 1',
                  title: 'Title 1'
              },
              {
                  itemImageSrc: './assets/banner-home-2.svg',
                  thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria2s.jpg',
                  alt: 'Description for Image 2',
                  title: 'Title 2'
              },
              {
                  itemImageSrc: './assets/banner-home-3.svg',
                  thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria3s.jpg',
                  alt: 'Description for Image 3',
                  title: 'Title 3'
              }
          ];
  
          this.countries = [{
              "name": "Afghanistan",
              "code": "AF"
          },
          {
              "name": "Afghanistan",
              "code": "AF"
          }];
  
          this.products = [{
              id: '1000',
              code: 'f230fh0g3',
              name: 'Bamboo Watch',
              description: 'Product Description',
              image: './assets/radisson-hotel-agra.svg',
              price: 65,
              category: 'Accessories',
              quantity: 24,
              inventoryStatus: 'INSTOCK',
              rating: 5,
              city:'Gurgaon'
          },{
              id: '1000',
              code: 'f230fh0g3',
              name: 'Bamboo Watch',
              description: 'Product Description',
              image: './assets/radison-2.svg',
              price: 65,
              category: 'Accessories',
              quantity: 24,
              inventoryStatus: 'INSTOCK',
              rating: 5,
              city: 'Banglore'
          },{
              id: '1000',
              code: 'f230fh0g3',
              name: 'Bamboo Watch',
              description: 'Product Description',
              image: './assets/hilton-agra.svg',
              price: 65,
              category: 'Accessories',
              quantity: 24,
              inventoryStatus: 'INSTOCK',
              rating: 5,
              city:'Chennai'
          },{
              id: '1000',
              code: 'f230fh0g3',
              name: 'Bamboo Watch',
              description: 'Product Description',
              image: './assets/hilton-agra.svg',
              price: 65,
              category: 'Accessories',
              quantity: 24,
              inventoryStatus: 'INSTOCK',
              rating: 5,
              city:'Bhopal'
          },{
              id: '1000',
              code: 'f230fh0g3',
              name: 'Bamboo Watch',
              description: 'Product Description',
              image: './assets/hilton-agra.svg',
              price: 65,
              category: 'Accessories',
              quantity: 24,
              inventoryStatus: 'INSTOCK',
              rating: 5
          },{
              id: '1000',
              code: 'f230fh0g3',
              name: 'Bamboo Watch',
              description: 'Product Description',
              image: './assets/hilton-agra.svg',
              price: 65,
              category: 'Accessories',
              quantity: 24,
              inventoryStatus: 'INSTOCK',
              rating: 5
          },{
              id: '1000',
              code: 'f230fh0g3',
              name: 'Bamboo Watch',
              description: 'Product Description',
              image: './assets/hilton-agra.svg',
              price: 65,
              category: 'Accessories',
              quantity: 24,
              inventoryStatus: 'INSTOCK',
              rating: 5
          }];
          if (localStorage.getItem("selectedCity") !== null) {
              this.cityCode = localStorage.getItem('selectedCity') || '';
              this.city = localStorage.getItem('cityName') || '';
            }
        // });
    }
    test(){
        console.log('testing load img----')
    }
sheetApi(){
const sheetName ='Venues (Home)';
const url = `${environment.sheet_url}${environment.sheet_id}/values/${sheetName}?key=${environment.sheet_api_key}`;
this.httpClient.get(url, {
   
}).subscribe((response:any): void => {
   console.log('cities--',response);
   let arrr = response.values.map((x:any) =>({
    property_name:x[1],
    EPID:x[2],
    property_type:x[3],
    city:x[4],
    pax:x[11],
    rooms:x[13],
    banquest:x[12],
    blue_tick:x[22],
    img:`${environment.imgPath}Evershine Properties Listing database${this.city}/${x[2]}/${x[2]}-001`
    
  }))

  this.venues = arrr;
  this.venues = this.venues.filter((val:any)=> val.city !== 'City')
}); 
}

defaultImage(){
    return 'https://storage.cloud.google.com/staging.winged-tenure-439711-c4.appspot.com/images/Evershine%20Properties%20Listing%20database/%24Default%20Images/EP-DOUTRE-HLT-AGR-001.png'
}
//   @Output() change = new EventEmitter();

  onClick() {
      this.iLike = !this.iLike;
      this.totalLikes += this.iLike ? 1 : -1;
    //   this.change.emit({newValue: this.iLike, newTotal: this.totalLikes});
  }
  onSubmit() {
    // TODO: Use EventEmitter with form value
    console.warn(this.profileForm.value);
  }
  filterCountry(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.countries as any[]).length; i++) {
        let country = (this.countries as any[])[i];
        if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(country);
        }
    }

    this.filteredCountries = filtered;
}

showDialog(imgSrc:any,desc:any) {
    this.visible = true;
    this.prevSrc = imgSrc;
    this.prevDesc = desc;
}
// getSeverity(status: string) {
//     switch (status) {
//         case 'INSTOCK':
//             return 'success';
//         case 'LOWSTOCK':
//             return 'warning';
//         case 'OUTOFSTOCK':
//             return 'danger';
//     }
// }
}
