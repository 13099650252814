import { Routes } from '@angular/router';
import { AppComponent } from './app.component';
import {  DesktopGuard, MobileGuard } from '../shared/guard/mobile-guard.guard';
import { DesktopComponent } from './desktop/desktop.component';
import { HeaderComponent } from '../shared/header/header.component';
import { MobileComponent } from '../mobile/mobile.component';
import { DesktopHomeComponent } from './desktop/desktop-home/desktop-home.component';
import { MobileHomeComponent } from '../mobile/mobile-home/mobile-home.component';
import { VenueListComponent } from './desktop/venue-list/venue-list.component';
import { NotFoundComponent } from '../shared/not-found/not-found.component';


export const routes: Routes = [
    {
        path: '',
        component: DesktopComponent,
        canActivate: [DesktopGuard],
        children: [
          {
            path:'',
            component:DesktopHomeComponent
          },
          {
            path:'home',
            component:DesktopHomeComponent
          },
          {
            path:'venues',
            component:VenueListComponent
          },
          { path: '**', component: NotFoundComponent },
        ]
      },
      {
        path: 'm',
        component: MobileComponent,
        canActivate: [MobileGuard],
        children: [
          {
            path:'home',
            component:MobileHomeComponent
          },          
          {
          path:'home',
          component:MobileHomeComponent
        }]
      },
      
];
