<!-- <app-header></app-header> -->
<!-- <div class="router-outlet-container" #myElem> -->
    <!-- <p>not-found works!</p> -->
<div class="text-center mt-5">
    <!-- <img alt="dropdown icon" src="./assets/Evershine app.svg"> -->
    <div class="container">
        <div class="phone">
            <img src="./assets/Evershine app.svg" alt="Phone Mockup">
        </div>
        <div class="content">
            <h1>Something Extraordinary is Coming</h1>
            <p>We’re crafting a unique experience for your dream events. Be the first to know!</p>
            <form class="email-form">
                <input type="email" placeholder="Enter your email" required>
                <button type="submit">Signup</button>
            </form>
        </div>
    </div>

</div>
    
<!-- <app-footer></app-footer> -->
<!-- </div> -->



