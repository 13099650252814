<div class="overflow-x-hidden">
<p-galleria 
[value]="images" 
[indicatorsPosition]="'bottom'" 
[showIndicators]="true" 
[showThumbnails]="false" 
[showIndicatorsOnItem]="'true'" 
[responsiveOptions]="responsiveOptions" 
[changeItemOnIndicatorHover]="false" 
[containerStyle]="{'width': '100%'}">
    <ng-template pTemplate="item" let-item >
        <!-- <img [src]="item.itemImageSrc" style="width: 100%; display: block;" /> -->
        <p-image 
    src="{{item.itemImageSrc}}" 
    alt="Image"
    
     styleClass="pImage"/>
        
          
           <div class="search-icon zIndex-99">
            <img  src="./assets/search.svg" (load)="test()" height="20" alt="logo" class="position-relative margins zIndex-99">
        </div>
        <p-autoComplete
        [(ngModel)]="selectedCountry"
        [dropdown]="true"
        [suggestions]="filteredCountries"
        (completeMethod)="filterCountry($event)"
        optionLabel="name" class="position-absolute zIndex-99"
        placeholder="Find Vendors in Gurgaon" >
        <ng-template ></ng-template></p-autoComplete>
        
        <span class="position-absolute mb-5 pb-5 color-white font-weight-bold font-size-4 zIndex-99">Start Planning Your Wedding!</span>
  
    </ng-template>
</p-galleria>
<div class="main-margins">
    <div class="mt-25 ml-4 pl-2 d-flex mb-3">
        <span class="search-font">Popular Venue Searches {{this.selectedCity}}</span>
        <div class="ml-auto padd-right"> <p-button label="View all venues" styleClass="vendor-button" /></div>
    </div>
<!-- <p-carousel 
[value]="products" 
[numVisible]="4" 
[numScroll]="1" 
[circular]="false" 
[responsiveOptions]="responsiveOptions">
    <ng-template let-product pTemplate="item"> -->
        <div class="parent-for ml-3" >
            <div *ngFor="let venue of venues | filter:selectedCity">
        
        <div class="border-1 surface-border border-round mr-3">
           
            <div class="mb-3 d-inline-grid">
                <!-- <div class="card flex justify-content-center"> -->
                <div class="relative mx-auto position-relative d-inline-flex venues topgrad">
                    <img 
                        src="{{ venue.img }}" 
                        [alt]="venue.property_name" 
                        class="w-full border-round d-block border-rad-top" height="180" width="312" (error)="venue.img = defaultImg" (load)="test()"/>
                        <!-- <div id="main-content"> -->
                         
                        <!-- <i class="pi pi-heart-fill position-absolute t-0 " [ngStyle]="{'color' : (heartColor ?'red':'none')}" (click)="heartColor = !heartColor"></i> -->
                        <i class="pi pi-heart-fill position-absolute t-0 zIndex-99" [ngStyle]="{'color' : (heartColor ?'red':'none')}" (click)="heartColor = !heartColor"></i>
                        <img 
                        src="./assets/i-icon.svg"
                        class="position-absolute b-0 zIndex-99"  pTooltip="The Oberoi Amarvilas"
                        tooltipPosition="right" />
                        <p-button label="{{venue.city}}"  styleClass="city position-absolute"/>
                        
                          <!-- </div> -->
                    <!-- <p-tag 
                        [value]="product.inventoryStatus" 
                        [severity]="getSeverity(product.inventoryStatus)" 
                        class="absolute" 
                        [ngStyle]="{ 'left.px': 5, 'top.px': 5 }" /> -->
                </div>
                <div class="dark-bottom col-md-12 d-flex">
                    <div class="col-md-4"><img 
                        src="./assets/capacity.svg"
                        class="icons-venue"/>
                        <span class="venue-details">{{venue.pax}} </span></div>
                        
                    <div class="col-md-4 text-center"><img 
                        src="./assets/room.svg"
                        class="icons-venue "/>
                    <span class="venue-details">{{venue.rooms}} room</span></div>
                    <div class="col-md-4 text-left"><img 
                        src="./assets/banquet.svg"
                        class="icons-venue" />
                    <span class="venue-details">{{venue.banquest}} Banquests</span></div>
                </div>
            <!-- </div> -->
            </div>
            <div class=" col-12">
            <div class="card-name col-12">
                <div class="card-title">
                    {{venue.property_name}} <img *ngIf="venue.blue_tick == 'yes'"
                    src="./assets/assured.svg"
                    class="icons-venue-2"/></div>
               
            </div>
            <div class="col-12 d-flex mt-2">
                <div class="card-sub-title col-8"> {{venue.property_type}}</div>
                <!-- <div class=""></div> -->
                <div class="card-sub-title ml-2 col-4" ><i class="pi pi-star-fill review-font " [ngStyle]="{'color' : '#F8BE4E'}" ></i> <span class="review-font">4.7 (15 reviews)</span></div>
            </div>
        </div>
            <!-- <div class="mb-3 font-medium">
                {{ product.name }}
            </div>
            <div class="flex justify-content-between align-items-center">
            <div class="mt-0 font-semibold text-xl">
                {{ '$' + product.price }}
            </div>
                <span>
                    <p-button icon="pi pi-heart" severity="secondary" [outlined]="true" />
                    <p-button icon="pi pi-shopping-cart" styleClass="ml-2" />
                </span>
            </div> -->
        </div>
    <!-- </div> -->
    <!-- </ng-template>
</p-carousel> -->
</div>
</div>
<div class="mt-25 ml-4 pl-2 d-flex mb-3">
    <span class="search-font">Search by Vendors</span>
    </div>
    <div class="col-md-12 pad-5">
        <div class="col-md-3 mx-auto position-relative d-inline-flex img-1 container overflow-hidden venues1 topgrad1">
            <!-- <p-image 
        src="./assets/first-vendor.svg" 
        alt="Image" 
         styleClass="box vendor-style"/> -->
         <img 
         src="./assets/first-vendor.svg"
         class="box vendor-style"/>
         <p-button label="Bridal Makeup"  styleClass="vendor position-absolute"/>
        </div>
        
        <div class="col-md-3 mx-auto position-relative d-inline-flex overflow-hidden">
            <img 
            src="./assets/second-vendor.svg"
            class="box vendor-style"/>
            <!-- <p-image 
        src="./assets/second-vendor.svg" 
        alt="Image" 
         styleClass="box vendor-style"/> -->
         <p-button label="Photography & Videography"  styleClass="vendor position-absolute"/>
        </div>
        <div class="col-md-3 mx-auto position-relative d-inline-flex overflow-hidden">
            <img 
            src="./assets/third-vendor.svg"
            class="box vendor-style"/>
            <!-- <p-image 
        src="./assets/third-vendor.svg" 
        alt="Image" 
         styleClass="box vendor-style"/> -->
         
         <p-button label="Wedding Planners"  styleClass="vendor position-absolute"/>
        </div>
        <div class="col-md-3 mx-auto position-relative d-inline-flex overflow-hidden">
            <!-- <p-image 
        src="./assets/forth-vendor.svg" 
        alt="Image" 
         styleClass="box vendor-style"/> -->
         <img 
         src="./assets/forth-vendor.svg"
         class="box vendor-style"/>
         <p-button label="Decor & Floral Design"  styleClass="vendor position-absolute"/>
        </div>
        <div class="col-md-3 mx-auto position-relative d-inline-flex overflow-hidden">
            <!-- <p-image 
        src="./assets/fifth-vendor.svg" 
        alt="Image" 
         styleClass="box vendor-style"/> -->
         <img 
         src="./assets/fifth-vendor.svg"
         class="box vendor-style"/>
         <p-button label="DJ & Sound"  styleClass="vendor position-absolute"/>
        </div>
        <div class="col-md-3 mx-auto position-relative d-inline-flex overflow-hidden">
            <!-- <p-image 
        src="./assets/sixth-vendor.svg" 
        alt="Image" 
         styleClass="box vendor-style"/> -->
         <img 
         src="./assets/sixth-vendor.svg"
         class="box vendor-style"/>
         <p-button label="Dance & Choreography"  styleClass="vendor position-absolute"/>
        </div>
        <div class="col-md-3 mx-auto position-relative d-inline-flex overflow-hidden">
            <!-- <p-image 
        src="./assets/seventh-vendor.svg" 
        alt="Image" 
         styleClass="box vendor-style"/> -->
         <img 
         src="./assets/seventh-vendor.svg"
         class="box vendor-style"/>
         <p-button label="Entertainment & Performers"  styleClass="vendor position-absolute"/>
        </div>
        <div class="col-md-3 mx-auto position-relative d-inline-flex overflow-hidden">
            <!-- <p-image 
        src="./assets/last-vendor.svg" 
        alt="Image" 
         styleClass="box vendor-style"/> -->
         <img 
         src="./assets/last-vendor.svg"
         class="box vendor-style"/>
         <p-button label="Catering & Dining"  styleClass="vendor position-absolute"/>
        </div>
        
    </div>
  
   
    <!-- <div class="ml-auto padd-right"> <p-button label="View all venues" styleClass="vendor-button" /></div> -->
</div>
<div class="details-form d-flex mt-25">

    <div class="col-md-7 big-section">
        <div class="main-margins mt-6">
            <span class="search-font">A comprehensive platform for your Dream event</span>
            <div>
                <p class="card-sub-title-form mt-4 line-height-1">
                    <b>Evershine Events</b> offers easy-to-use, comprehensive solutions to craft your dream wedding. We make planning seamless, turning every idea into reality, so you can experience each moment without worry. From unique venue selections and personalized decor to engaging experiences for your guests, we bring together the best of everything to create a wedding that’s truly yours.

                </p>
                <p class="card-sub-title-form mt-4 line-height-1"><span class="sub-head-style">Plan with Ease</span> - From ideas to the final details, our team ensures every step is beautifully managed</p>

                <p class="card-sub-title-form mt-4 line-height-1"><span class="sub-head-style">Celebrate with Joy</span> -  Increase guest excitement with mxemorable experiences and personalized touches.</p>
                <p class="card-sub-title-form mt-4 line-height-1"><span class="sub-head-style">Cherish Every Moment</span> -  Let us handle the logistics so you can focus on the magic of your big day.</p>
            </div>
            <div class="marg-t-4">
                <span class="search-font ">Let’s create a wedding that tells your story, effortlessly</span>
            </div>
            <div class="mt-25">
                <p-button label="Register as a Vendor" styleClass="grad-button-wedd" />
            </div>
        </div>
    </div>
    <div class="col-md-5 small-section ">
        <div class="main-margins mt-6">
            <span class="search-font color-white">Get a Customized Wedding Proposal</span>

            <div class="form-dimension mt-3 position-absolute">
                <div class="margins-small ">
                    <div class="margin-small-top-bottom">
                        <div class="font-18 mt-5">Your Perfect Event Starts Here,</div>
                    <div class="font-18 font-popins-smebold">Let’s Get to Know You</div>
                    <div class="col-md-12 mt-25 col-12-pad-0 justify-content-around">
                        <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
                            <div class="mb-2 ">
                        <input type="text" id="name" pInputText formControlName="name" placeholder="Username" class="col-md-6 input-css mr-2"/>
                        <!-- <label for="name">Username</label> -->
                        <input type="text" id="phone" pInputText formControlName="phone" placeholder="+91" class="col-md-6 input-css"/>
                    </div>
                    <div class="mb-2">
                        <input type="text" id="email" pInputText formControlName="email" placeholder="Email" class="col-md-6 input-css mr-2"/>
                        <!-- <label for="name">Username</label> -->
                        <input type="text" pInputText id="date" formControlName="date" placeholder="Function Date" class="col-md-6 input-css"/>
                    </div>
                    <div class="mb-2">
                        <input type="text" id="guests" pInputText formControlName="guests" placeholder="# of guests (min 50)" class="col-md-6 input-css mr-2"/>
                        <!-- <label for="name">Username</label> -->
                        <input type="text" id="rooms" pInputText formControlName="rooms" placeholder="Number of rooms" class="col-md-6 input-css"/>
                    </div>
                    <!-- <div class="flex justify-content-center"> -->
                        <div class="d-flex mt-4">
                            <span class="f-type">Function Type</span>
                            <div class="flex align-items-center ml-4">
                                <p-radioButton 
                                    value="prewed" 
                                    formControlName="function" 
                                    inputId="prewed" />
                                <label for="prewed" class="ml-2 mb-0 card-sub-title line-height-1">
                                    Pre Wedding
                                </label>
                            </div>
                            
                            <div class="flex align-items-center ml-4">
                                <p-radioButton 
                                    value="wed" 
                                    formControlName="function" 
                                    inputId="wed" />
                                <label for="wed" class="ml-2 mb-0 card-sub-title line-height-1">
                                    Wedding
                                </label>
                            </div>                           
                            
                        </div>

                        <div class="d-flex mt-4">
                            <span class="f-type">Function Time</span>
                            <div class="flex align-items-center ml-4">
                                <p-radioButton 
                                    value="day" 
                                    formControlName="functionTime" 
                                    inputId="day" />
                                <label for="day" class="ml-2 mb-0 card-sub-title line-height-1">
                                    Day
                                </label>
                            </div>
                            
                            <div class="flex align-items-center ml-4">
                                <p-radioButton 
                                    value="eve" 
                                    formControlName="functionTime" 
                                    inputId="eve" />
                                <label for="eve" class="ml-2 mb-0 card-sub-title line-height-1">
                                    Evening
                                </label>
                            </div>     
                            
                            
                            
                        </div>
                        <div class="mt-25">
                            <p-button label="Submit" type="submit" styleClass="grad-button-enq" />
                        </div>
                        <span class="disclamer">Complete information ensures you get accurate and timely responses</span>
                    <!-- </div> -->
                    </form>
                    </div>
                    </div>
                    
                </div>
                

            </div>
        </div>
    </div>
</div>

<div class="main-margins mb-5">
    <div class="mt-25 ml-4 pl-2 d-flex mb-3">
        <span class="search-font">Our Venues Partners</span>
        
        </div>
        <div class=" col-12-pad-0 row marg-0 overflow-hidden">
            <div class="d-grid col-md-2 w-100">
                <div class="mb-1 position-relative d-inline-flex" >
                    <!-- <p-image 
                    src="./assets/grid-1.svg" 
                    alt="Image" 
                     styleClass=" col-12-pad-0 grid-images d-block"/> -->
                     <img 
                     src="./assets/grid-1.svg"
                     class="img-setting img-fluid" (click)="showDialog('./assets/grid-1.svg','This is going to be the title for the trending Indian wedding styles')"/>
                     <span class="position-absolute bt-0">This is going to be the title for the trending Indian wedding styles</span>
                </div>
                    
                <div class="mb-1 position-relative d-inline-flex">
                    <!-- <p-image 
                    src="./assets/grid-1.svg" 
                    alt="Image" 
                     styleClass=" col-12-pad-0 grid-images d-block"/> -->
                     <img 
                     src="./assets/secondline-1.svg"
                     class=" img-setting img-fluid" />
                     <span class="position-absolute bt-0">This is going to be the title for the trending Indian wedding styles</span>
                </div>         
           
            <div class="mb-1 position-relative d-inline-flex">
                <!-- <p-image 
                src="./assets/grid-1.svg" 
                alt="Image" 
                 styleClass=" col-12-pad-0 grid-images d-block"/> -->
                 <img 
                 src="./assets/grid-2.svg"
                 class="img-setting img-fluid"/>
                 <span class="position-absolute bt-0">This is going to be the title for the trending Indian wedding styles</span>
            </div> 
             
            </div>
            <div class="d-grid col-md-2 ">
                <div class="mb-1 position-relative d-inline-flex">
                <img
                src="./assets/grid-2.svg" 
                alt="Image"
                class="img-setting img-fluid" 
                 />
                </div>
                <div class="mb-1 position-relative d-inline-flex">
                 <img
                 src="./assets/secondline-2.svg" 
                 alt="Image" 
                 class="img-setting img-fluid" 
                  />
                </div>
                <div class="mb-1 position-relative d-inline-flex">
             <img 
            src="./assets/grid-3.svg" 
            alt="Image" 
            class="img-setting img-fluid" 
             />
            </div>
             
            </div>
            <div class="d-grid col-md-2">
                <div class="mb-1 position-relative d-inline-flex"> 
                <img
                src="./assets/grid-3.svg" 
                alt="Image" 
                class="img-setting img-fluid" 
                 />
                </div>
                <div class="mb-1 position-relative d-inline-flex"> 
                 <img
                 src="./assets/secondline-2.svg" 
                 alt="Image" 
                 class="img-setting img-fluid" 
                  />
                  </div>
                  <div class="mb-1 position-relative d-inline-flex"> 
                <img
                src="./assets/grid-2.svg" 
                alt="Image" 
                class="img-setting img-fluid" 
                />             
                    </div>
            
            </div>
            <div class="d-grid col-md-2">
                <div class="mb-1 position-relative d-inline-flex"> 
                <img 
                src="./assets/grid-2.svg" 
                alt="Image" 
                class="img-setting img-fluid" 
                /> 
                </div>
                <div class="mb-1 position-relative d-inline-flex"> 
                <img
                src="./assets/grid-3.svg" 
                alt="Image" 
                class="img-setting img-fluid" 
                 />
                 </div>
                 <div class="mb-1 position-relative d-inline-flex"> 
                 <img 
                 src="./assets/secondline-2.svg" 
                 alt="Image" 
                 class="img-setting img-fluid" 
                 />
                 </div>
                           
                    
            
            </div>
            <div class="d-grid col-md-2">
                <div class="mb-1 position-relative d-inline-flex"> 
                <img
                src="./assets/grid-3.svg" 
                alt="Image" 
                class="img-setting img-fluid"  />
                </div>
                <div class="mb-1 position-relative d-inline-flex"> 
                 <img 
                 src="./assets/secondline-2.svg" 
                 alt="Image" 
                 class="img-setting img-fluid" />
                </div>
                <div class="mb-1 position-relative d-inline-flex"> 
                  <img
                  src="./assets/grid-2.svg" 
                  alt="Image" 
                  class="img-setting img-fluid" /> 
            </div>
            </div>
            <div class="d-grid col-md-2">
                <div class="mb-1 position-relative d-inline-flex"> 
                <img 
                src="./assets/grid-2.svg" 
                alt="Image" 
                class="img-setting img-fluid" /> 
                </div>
                <div class="mb-1 position-relative d-inline-flex"> 
                <img 
                src="./assets/grid-3.svg" 
                alt="Image" 
                class="img-setting img-fluid" />
                </div>
                <div class="mb-1 position-relative d-inline-flex"> 
                 <img
                 src="./assets/secondline-2.svg" 
                 alt="Image" 
                 class="img-setting img-fluid" />
                </div>
                
            
            </div>  
            <!-- <p-image 
            src="./assets/grid-1.svg" 
            alt="Image" 
             styleClass="col-md-2 col-12-pad-0 grid-images"/>
             <p-image 
            src="./assets/grid-2.svg" 
            alt="Image" 
             styleClass="col-md-2 col-12-pad-0 grid-images"/>
             <p-image 
            src="./assets/grid-3.svg" 
            alt="Image" 
             styleClass="col-md-2 col-12-pad-0 grid-images" />
             <p-image 
            src="./assets/grid-4.svg" 
            alt="Image" 
             styleClass="col-md-2 col-12-pad-0 grid-images"/>
            
             <p-image 
            src="./assets/grid-5.svg" 
            alt="Image" 
             styleClass="col-md-2 col-12-pad-0 grid-images"/>
             <p-image 
            src="./assets/grid-6.svg" 
            alt="Image" 
             styleClass="col-md-2 col-12-pad-0 grid-images"/> -->
             <!-- second line---- -->
             <!-- <p-image 
            src="./assets/secondline-1.svg" 
            alt="Image" 
             styleClass="col-md-2 col-12-pad-0 grid-images"/>
             <p-image 
            src="./assets/secondline-2.svg" 
            alt="Image" 
             styleClass="col-md-2 col-12-pad-0 grid-images"/>
             <p-image 
            src="./assets/secondline-3.svg" 
            alt="Image" 
             styleClass="col-md-2 col-12-pad-0 grid-images"/>
             <p-image 
            src="./assets/secondline-4.svg" 
            alt="Image" 
             styleClass="col-md-2 col-12-pad-0 grid-images"/>
             <p-image 
            src="./assets/secondline-5.svg" 
            alt="Image" 
             styleClass="col-md-2 col-12-pad-0 grid-images"/>
             <p-image 
            src="./assets/secondline-6.svg" 
            alt="Image" 
             styleClass="col-md-2 col-12-pad-0 grid-images"/> -->
        </div>
     
        
    <div class="mt-25 ml-4 pl-2 d-flex mb-3">
        <span class="search-font">Our Venues Partners</span>
        </div>
        <div class="col-md-12 pad-5">
            <div class="col-3 d-inline-grid rect">
                <p-image 
            src="./assets/le-meridian.svg" 
            alt="Image" 
             styleClass="box vendor-style1 img-fluid"/>
                <!-- <p-image 
            src="./assets/first-vendor.svg" 
            alt="Image" 
             styleClass="box vendor-style"/>
             <p-button label="Bridal Makeup"  styleClass="vendor position-absolute"/> -->
            </div>
            
            <div class="col-3 d-inline-grid rect">
                <p-image 
            src="./assets/marriot.svg" 
            alt="Image" 
             styleClass="box vendor-style1"/>
                <!-- <p-image 
            src="./assets/first-vendor.svg" 
            alt="Image" 
             styleClass="box vendor-style"/>
             <p-button label="Bridal Makeup"  styleClass="vendor position-absolute"/> -->
            </div>
            <div class="col-3 d-inline-grid rect">
                <p-image 
            src="./assets/radison.svg" 
            alt="Image" 
             styleClass="box vendor-style1"/>
                <!-- <p-image 
            src="./assets/first-vendor.svg" 
            alt="Image" 
             styleClass="box vendor-style"/>
             <p-button label="Bridal Makeup"  styleClass="vendor position-absolute"/> -->
            </div>
            <div class="col-3 d-inline-grid rect">
                <p-image 
            src="./assets/radison.svg" 
            alt="Image" 
             styleClass="box vendor-style1"/>
                <!-- <p-image 
            src="./assets/first-vendor.svg" 
            alt="Image" 
             styleClass="box vendor-style"/>
             <p-button label="Bridal Makeup"  styleClass="vendor position-absolute"/> -->
            </div>
            <div class="col-3 d-inline-grid rect">
                <p-image 
            src="./assets/radison.svg" 
            alt="Image" 
             styleClass="box vendor-style1"/>
                <!-- <p-image 
            src="./assets/first-vendor.svg" 
            alt="Image" 
             styleClass="box vendor-style"/>
             <p-button label="Bridal Makeup"  styleClass="vendor position-absolute"/> -->
            </div>
            <div class="col-3 d-inline-grid rect">
                <p-image 
            src="./assets/radison.svg" 
            alt="Image" 
             styleClass="box vendor-style1"/>
                <!-- <p-image 
            src="./assets/first-vendor.svg" 
            alt="Image" 
             styleClass="box vendor-style"/>
             <p-button label="Bridal Makeup"  styleClass="vendor position-absolute"/> -->
            </div>
            <div class="col-3 d-inline-grid rect">
                <p-image 
            src="./assets/radison.svg" 
            alt="Image" 
             styleClass="box vendor-style1"/>
                <!-- <p-image 
            src="./assets/first-vendor.svg" 
            alt="Image" 
             styleClass="box vendor-style"/>
             <p-button label="Bridal Makeup"  styleClass="vendor position-absolute"/> -->
            </div>
            <div class="col-3 d-inline-grid rect">
                <p-image 
            src="./assets/crown.svg" 
            alt="Image" 
             styleClass="box vendor-style1"/>
                <!-- <p-image 
            src="./assets/first-vendor.svg" 
            alt="Image" 
             styleClass="box vendor-style"/>
             <p-button label="Bridal Makeup"  styleClass="vendor position-absolute"/> -->
            </div>
            
        </div>
    <div class="mt-25 ml-4 pl-2 d-flex mb-3">
        <span class="search-font">Featured Vendors</span>
        <div class="ml-auto padd-right"> <p-button label="View all venues" styleClass="vendor-button" /></div>
    </div>
<p-carousel 
[value]="products" 
[numVisible]="4" 
[numScroll]="1" 
[circular]="false" 
[responsiveOptions]="responsiveOptions">
    <ng-template let-product pTemplate="item">
        
        <div class="border-1 surface-border border-round ">
           
            <div class="mb-3 d-inline-grid">
                <!-- <div class="card flex justify-content-center"> -->
                <div class="relative mx-auto position-relative d-inline-flex venues topgrad">
                    <img 
                        src="{{ product.image }}" 
                        [alt]="product.name" 
                        class="w-full border-round d-block" height="180" width="312"/>
                        <!-- <div id="main-content"> -->
                         
                        <!-- <i class="pi pi-heart-fill position-absolute t-0 " [ngStyle]="{'color' : (heartColor ?'red':'none')}" (click)="heartColor = !heartColor"></i> -->
                        <i class="pi pi-heart-fill position-absolute t-0 zIndex-99" [ngStyle]="{'color' : (heartColor ?'red':'none')}" (click)="heartColor = !heartColor"></i>
                        <img 
                        src="./assets/i-icon.svg"
                        class="position-absolute b-0 zIndex-99" />
                        <p-button label="{{product.city}}"  styleClass="city position-absolute"/>
                          <!-- </div> -->
                    <!-- <p-tag 
                        [value]="product.inventoryStatus" 
                        [severity]="getSeverity(product.inventoryStatus)" 
                        class="absolute" 
                        [ngStyle]="{ 'left.px': 5, 'top.px': 5 }" /> -->
                </div>
                <!-- <div class="dark-bottom col-md-12 d-flex">
                    <div class="col-md-4"><img 
                        src="./assets/capacity.svg"
                        class="icons-venue"/>
                        <span class="venue-details">250-700pax</span></div>
                        
                    <div class="col-md-4 text-center"><img 
                        src="./assets/room.svg"
                        class="icons-venue "/>
                    <span class="venue-details">84 room</span></div>
                    <div class="col-md-4 text-left"><img 
                        src="./assets/banquet.svg"
                        class="icons-venue" />
                    <span class="venue-details">9 Banquests</span></div>
                </div> -->
            <!-- </div> -->
            </div>
            <div class="d-flex">
            <div class="card-name">
                <div class="card-title">The Oberoi Amarvilas<img 
                    src="./assets/assured.svg"
                    class="icons-venue"/></div>
                <div class="card-sub-title">Four Star and above Hotels</div>
            </div>
            <div>
                <div class="card-title"></div>
                <div class="card-sub-title ml-4"><i class="pi pi-star-fill position-absolute  " [ngStyle]="{'color' : '#F8BE4E'}" ></i> <span class="ml-4">4.7 (15 reviews)</span></div>
            </div>
        </div>
            <!-- <div class="mb-3 font-medium">
                {{ product.name }}
            </div>
            <div class="flex justify-content-between align-items-center">
            <div class="mt-0 font-semibold text-xl">
                {{ '$' + product.price }}
            </div>
                <span>
                    <p-button icon="pi pi-heart" severity="secondary" [outlined]="true" />
                    <p-button icon="pi pi-shopping-cart" styleClass="ml-2" />
                </span>
            </div> -->
        </div>
    <!-- </div> -->
    </ng-template>
</p-carousel>
<div class=" mt-25">
    <span class="search-font d-inherit text-center">Testimonials</span>
    <div class="d-flex mt-25 justify-content-center">
<p-card class="testi ">
    <div>
    <p-image 
    src="./assets/testmonial.svg" 
    alt="Image"
     styleClass="pImagetestimonial position-absolute"/>
    </div>
     <div class="stars-positions">
        <i class="pi pi-star-fill " [ngStyle]="{'color' : '#F8BE4E','font-size':'2rem'}" ></i> 
        <i class="pi pi-star-fill " [ngStyle]="{'color' : '#F8BE4E','font-size':'2rem'}" ></i> 
        <i class="pi pi-star-fill  " [ngStyle]="{'color' : '#F8BE4E','font-size':'2rem'}" ></i> 
        <i class="pi pi-star-fill " [ngStyle]="{'color' : '#F8BE4E','font-size':'2rem'}" ></i> 
        <i class="pi pi-star-fill " [ngStyle]="{'color' : '#F8BE4E','font-size':'2rem'}" ></i> 
     </div>
     <div class="font-18 font-popins-smebold text-center mt-4">javed Sheikh</div>
    <p class="m-0 text-center">
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis quis pulvinar libero. Curabitur ultricies tortor eu pharetra auctor. Vestibulum porttitor egestas malesuada. Nulla a lectus quam. Aenean laoreet lorem eros, vitae scelerisque mi laoreet a. Duis et neque finibus, porta lorem quis, vulputate risus. Ut lobortis quis ante sit amet efficitur."
    </p>
</p-card>

<p-card class="testi">
    <div>
        <p-image 
        src="./assets/testmonial.svg" 
        alt="Image"
         styleClass="pImagetestimonial position-absolute"/>
        </div>
         <div class="stars-positions">
            <i class="pi pi-star-fill " [ngStyle]="{'color' : '#F8BE4E','font-size':'2rem'}" ></i> 
            <i class="pi pi-star-fill " [ngStyle]="{'color' : '#F8BE4E','font-size':'2rem'}" ></i> 
            <i class="pi pi-star-fill  " [ngStyle]="{'color' : '#F8BE4E','font-size':'2rem'}" ></i> 
            <i class="pi pi-star-fill " [ngStyle]="{'color' : '#F8BE4E','font-size':'2rem'}" ></i> 
            <i class="pi pi-star-fill " [ngStyle]="{'color' : '#F8BE4E','font-size':'2rem'}" ></i> 
         </div>
         <div class="font-18 font-popins-smebold text-center mt-4">javed Sheikh</div>
        <p class="m-0 text-center">
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis quis pulvinar libero. Curabitur ultricies tortor eu pharetra auctor. Vestibulum porttitor egestas malesuada. Nulla a lectus quam. Aenean laoreet lorem eros, vitae scelerisque mi laoreet a. Duis et neque finibus, porta lorem quis, vulputate risus. Ut lobortis quis ante sit amet efficitur."
        </p>
</p-card>

<p-card class="testi">
    <div>
        <p-image 
        src="./assets/testmonial.svg" 
        alt="Image"
         styleClass="pImagetestimonial position-absolute"/>
        </div>
         <div class="stars-positions">
            <i class="pi pi-star-fill " [ngStyle]="{'color' : '#F8BE4E','font-size':'2rem'}" ></i> 
            <i class="pi pi-star-fill " [ngStyle]="{'color' : '#F8BE4E','font-size':'2rem'}" ></i> 
            <i class="pi pi-star-fill  " [ngStyle]="{'color' : '#F8BE4E','font-size':'2rem'}" ></i> 
            <i class="pi pi-star-fill " [ngStyle]="{'color' : '#F8BE4E','font-size':'2rem'}" ></i> 
            <i class="pi pi-star-fill " [ngStyle]="{'color' : '#F8BE4E','font-size':'2rem'}" ></i> 
         </div>
         <div class="font-18 font-popins-smebold text-center mt-4">javed Sheikh</div>
        <p class="m-0 text-center">
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis quis pulvinar libero. Curabitur ultricies tortor eu pharetra auctor. Vestibulum porttitor egestas malesuada. Nulla a lectus quam. Aenean laoreet lorem eros, vitae scelerisque mi laoreet a. Duis et neque finibus, porta lorem quis, vulputate risus. Ut lobortis quis ante sit amet efficitur."
        </p>
</p-card>

</div>

</div>
<div class=" mt-25 pt-5">
    <span class="search-font d-inherit text-center">FAQs</span>
    <div class="d-flex mt-25 justify-content-center">
        <p-accordion [activeIndex]="0" class="wid-accordian">
            <p-accordionTab [header]="" *ngFor="let tab of tabs">
                <ng-template pTemplate="header">
                    <span class="flex align-items-center gap-2 w-full">
                        <span class="accrodian-header">&#9632; {{tab.title}}</span>
                    </span>
                </ng-template>
                <p class="m-0">
                    {{ tab.content }}
                </p>
            </p-accordionTab>
        </p-accordion>
    </div>
    </div>

    <div class="mt-25 ml-4 pl-2 d-flex mb-3">
        <span class="search-font">Popular Blogs</span>
        <!-- <div class="ml-auto padd-right"> <p-button label="View all venues" styleClass="vendor-button" /></div> -->
    </div>
<p-carousel 
[value]="products" 
[numVisible]="4" 
[numScroll]="1" 
[circular]="false" 
[responsiveOptions]="responsiveOptions" styleClass="carousals">
    <ng-template let-product pTemplate="item">
        
        <div class="border-1 surface-border border-round  mb-3">
           
            <div class="mb-3 d-inline-grid">
                <!-- <div class="card flex justify-content-center"> -->
                <div class="relative mx-auto position-relative d-inline-flex venues topgrad">
                    <img 
                        src="{{ product.image }}" 
                        [alt]="product.name" 
                        class="w-full border-round d-block" height="180" width="312"/>
                        <!-- <div id="main-content"> -->
                         
                        <!-- <i class="pi pi-heart-fill position-absolute t-0 " [ngStyle]="{'color' : (heartColor ?'red':'none')}" (click)="heartColor = !heartColor"></i> -->
                        <i class="pi pi-heart-fill position-absolute t-0 zIndex-99" [ngStyle]="{'color' : (heartColor ?'red':'none')}" (click)="heartColor = !heartColor"></i>
                        <span class="position-absolute tl-0 zIndex-99">FEBRUARY 28, 2023</span>
                        <!-- <img 
                        src="./assets/i-icon.svg"
                        class="position-absolute b-0" /> -->
                        <p-button label="Blog catagory"  styleClass="blog position-absolute"/>
                          <!-- </div> -->
                    <!-- <p-tag 
                        [value]="product.inventoryStatus" 
                        [severity]="getSeverity(product.inventoryStatus)" 
                        class="absolute" 
                        [ngStyle]="{ 'left.px': 5, 'top.px': 5 }" /> -->
                </div>
                
            <!-- </div> -->
            </div>
            <div class="d-flex">
            <div class="card-name">
                <div class="card-title">Sparkling With Love: Manish </div>
                <div class="card-sub-title mt-4 line-height-1">With a career spanning over two decades, fashion maven, Manish Malhotra has established himself as...read more</div>
            </div>
            <!-- <div>
                <div class="card-title"></div>
                <div class="card-sub-title ml-4"><i class="pi pi-star-fill position-absolute  " [ngStyle]="{'color' : '#F8BE4E'}" ></i> <span class="ml-4">4.7 (15 reviews)</span></div>
            </div> -->
        </div>
            <!-- <div class="mb-3 font-medium">
                {{ product.name }}
            </div>
            <div class="flex justify-content-between align-items-center">
            <div class="mt-0 font-semibold text-xl">
                {{ '$' + product.price }}
            </div>
                <span>
                    <p-button icon="pi pi-heart" severity="secondary" [outlined]="true" />
                    <p-button icon="pi pi-shopping-cart" styleClass="ml-2" />
                </span>
            </div> -->
        </div>
    <!-- </div> -->
    </ng-template>
</p-carousel>
</div>
</div>
<!-- </div> -->

<p-dialog header="" [modal]="true" [(visible)]="visible" [style]="{ width: '100vw',height:'100vh' }">
    
    <p-splitter 
    [panelSizes]="[75, 25]" 
    [style]="{ height: '100vh' }" 
    styleClass="mb-5">
    <ng-template pTemplate>
        <div class="col flex align-items-center justify-content-center">
            <p-image 
    src="{{prevSrc}}" 
    alt="Image"  
    [preview]="true" 
    height="100%"/>
        </div>
    </ng-template>
    <ng-template pTemplate>
        <div class="col flex align-items-center justify-content-center">
            {{prevDesc}}
        </div>
    </ng-template>
</p-splitter>
</p-dialog>