<footer class="">
    <!-- <div class="col-md-12 d-block padding-footer"> -->
        
        <!-- <div class="collapse navbar-collapse d-block col-md-12 " id="navbarNav">
            <ul class="navbar-nav flex-row">
                <li class="nav-item" *ngFor="let item of navBar; let i = index" (click)="selectItem(i)"
                    [ngClass]="{'active': selectedItem === i}">
                    <a class="nav-link text-black">{{item}}</a>
                </li>

            </ul>

        </div> -->
        <div class="maintain-ratio pt-15">
            <div class="get-in pb-6">
                Inspiration for your wedding
            </div>
        <p-carousel [value]="navBar" [numVisible]="7" [numScroll]="3" [circular]="false" [showIndicators]="false"
            [responsiveOptions]="responsiveOptions">
            <ng-template let-product pTemplate="product" let-idx="index" class="'nav-item'">
                <!-- {{product}} -->
                <ul class="navbar-nav flex-row">
                    <li class="nav-item" (click)="selectItem(transform(navBar,product))"
                        [ngClass]="{'active': selectedItem === transform(navBar,product)}">
                        <a class="nav-link text-black">{{product }}</a>
                    </li>

                </ul>

                <!-- <div class="border-1 surface-border border-round m-2 p-3">
                    <div class="mb-3">
                        <div class="relative mx-auto">
                            <img 
                                src="https://primefaces.org/cdn/primeng/images/demo/product/{{ product.image }}" 
                                [alt]="product.name" 
                                class="w-full border-round" />
                            <p-tag 
                                [value]="product.inventoryStatus" 
                                [severity]="getSeverity(product.inventoryStatus)" 
                                class="absolute" 
                                [ngStyle]="{ 'left.px': 5, 'top.px': 5 }" />
                        </div>
                    </div>
                    <div class="mb-3 font-medium">
                        {{ product.name }}
                    </div>
                    <div class="flex justify-content-between align-items-center">
                    <div class="mt-0 font-semibold text-xl">
                        {{ '$' + product.price }}
                    </div>
                        <span>
                            <p-button icon="pi pi-heart" severity="secondary" [outlined]="true" />
                            <p-button icon="pi pi-shopping-cart" styleClass="ml-2" />
                        </span>
                    </div>
                </div> -->
            </ng-template>
        </p-carousel>
        <div class="black-line d-block col-md-12"></div>
        <div class="col-md-12 marg-top ">
            <div class="d-flex flex-wrap w-125">
                <ng-container *ngFor="let item of footertext">
                    <!-- <li> -->
                    <div class="wid-14 mb-4 d-grid">
                        <span class="font-bold">{{item.heading}}</span>
                        <span class="photo-copyright">{{item.subHeading}}</span>
                    </div>
                    <!-- </li> -->
                </ng-container>
            </div>
        </div>
        </div>
        <div class="gray-line col-md-12"></div>
<div class="maintain-ratio pt-15">
        

        
        <div class="col-md-12 d-flex pl-0 marg-top">

            <div class="addr col-md-4 pl-0">
                <ul class="pl-0 mb-0">
                    <li class="list-heading">Start Planning</li>
                    <li class="letter-spacing">Search by Vendor</li>
                    <li class="letter-spacing">Search by City</li>
                    <li class="letter-spacing">Download our App</li>
                    <li class="letter-spacing">Top Rated Vendor</li>
                    <li class="letter-spacing">Destination weeding</li>
                </ul>
            </div>
            <div class="addr col-md-4 pl-0">
                <ul class="pl-0 mb-0">
                    <li class="list-heading">Wedding Ideas</li>
                    <li class="letter-spacing">Wedding Blogs</li>
                    <li class="letter-spacing">Wedding Inspiration Gallery</li>
                    <li class="letter-spacing">Real Wedding</li>
                    <li class="letter-spacing">Submit Wedding</li>
                </ul>
            </div>
            <div class="addr col-md-4 pl-0">
                <ul class="pl-0 mb-0">
                    <li class="list-heading">Photo Gallery</li>
                    <li class="letter-spacing">Search by Vendor</li>
                    <li class="letter-spacing">Search by City</li>
                    <li class="letter-spacing">Download our App</li>
                    <li class="letter-spacing">Top Rated Vendor</li>
                    <li class="letter-spacing">Destination weeding</li>
                    <li class="letter-spacing">Search by Vendor</li>
                    <li class="letter-spacing">Search by City</li>
                    <li class="letter-spacing">Download our App</li>
                </ul>
            </div>
        </div>
        <div class="black-line col-md-12"></div>
        <div class="footer-dimensions col-md-12 d-flex">


            <!-- <div class="addr-new col-md-4 ">
                <img src="./assets/logo-footer.svg" class="position-relative " height="20" alt="logo">
                Evershine Events Pvt. Ltd. WeWork DLF Two Horizon Centre, DLF Phase 5, Sector 43, Golf Course Road, Gurugram, Haryana 122002<br />
                +91 8373963332, +91 8373963332
            </div> -->
            <div class="addr-new col-md-6">
                <img src="./assets/logo-footer.svg" height="30" alt="logo" class="position-relative">WeWork DLF Two
                Horizon Centre, DLF Phase 5, Sector 43, Golf Course Road, Gurugram, Haryana 122002 <span><span class="font-weight-bold">Contact:</span> +91
                9560085362, +91 8373963332, <span class="font-weight-bold">Email:</span> Hello&#64;evershineevents.com</span></div>
            <div class=" col-md-3 d-inline-block ">
                <div class="get-in">
                    Get in the Know
                </div>
                <div>
                    <input type="text" class="email-input" placeholder="Enter your email" pInputText />
                    <p-button label="Submit" styleClass="grad-button" />

                </div>
                <!-- <div class="pad-1">
                    
                </div> -->
            </div>
            <div class="col-md-3  top-4">
                <p-button label="Submit Wedding" styleClass="grad-button-wedd" />
                <p-button label="Register as a Vendor" styleClass="vendor-button" />
            </div>
            <div>

            </div>
        </div>
        <div class="black-line d-block col-md-12  marg-top"></div>
        <div class="footer-dimensions col-md-12 d-flex marg-top">
            <div class="photo-copyright">
                Photo copyright retained by photo owners, everything else © 2023 Evershine Events®
                Investor Relations | <span class="red-text">Privacy Policy </span>| <span class="red-text">Terms of
                    Service </span> | <span class="red-text">Policy</span>
            </div>
            <div class="social-position">
                <ul class="social-icon">
                    <li class="social-list pl-0"><img src="./assets/Insta Icon.svg" class="position-relative "
                            height="20" alt="logo"></li>
                    <li class="social-list pl-0"><img src="./assets/Face book Icon.svg" class="position-relative "
                            height="20" alt="logo"></li>
                    <li class="social-list pl-0"><img src="./assets/Twitter Icon.svg" class="position-relative "
                            height="20" alt="logo"></li>
                    <li class="social-list pl-0"><img src="./assets/Linkedin Icon.svg" class="position-relative "
                            height="20" alt="logo"></li>
                    <li class="social-list pl-0"><img src="./assets/Youtube Icon.svg" class="position-relative "
                            height="20" alt="logo"></li>
                </ul>
            </div>
        </div>
      
    </div>
    <div class="bottom-strip"></div>
    <!-- </div> -->
</footer>