import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenuItem } from 'primeng/api';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
// import * as $ from "jquery";
// import {MatTabsModule} from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DividerModule } from 'primeng/divider';
import { CarouselModule } from 'primeng/carousel';
@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [InputTextModule,ButtonModule,TabMenuModule,CommonModule,MatSlideToggleModule,DividerModule,CarouselModule ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  selectedCity = 'New York';
  navBar = ['Popular','Venues','Bridal Makeup', 'Photography & Videography','Wedding Planners', 'Decor & Floral Design', 'DJ & Sound', 'Dance & Choreography', 'Entertainment & Performers','Catering & Dining','Popular','Venuess','Bridal Makeup', 'Photography & Videography','Wedding Planners', 'Decor & Floral Design', 'DJ & Sound',]
 footertext=[{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'},{'heading':'The Oberoi Amarvilas','subHeading':'5-star Hotels'}]
  selectedItem: number | null = 1;
  items: MenuItem[] | undefined;
  responsiveOptions: any[] | undefined;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe([
      "(max-width: 768px)"
    ]).subscribe((result: BreakpointState) => {
      console.log('result---',result)
      if (result.matches) {
          // hide stuff        
      } else {
          // show stuff
      }
    });
  }
  
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      // Safe to use `window` here
      console.log(window.innerWidth);
    }
//  console.log(window.innerWidth)
    this.items = [
      { label: 'Dashboard', icon: 'pi pi-home' },
      { label: 'Transactions', icon: 'pi pi-chart-line' },
      { label: 'Products', icon: 'pi pi-list' },
      { label: 'Messages', icon: 'pi pi-inbox' }
  ]
    this.selectedCity = 'NY';
    this.responsiveOptions = [
      {
          breakpoint: '1199px',
          numVisible: 1,
          numScroll: 1
      },
      {
          breakpoint: '991px',
          numVisible: 2,
          numScroll: 1
      },
      {
          breakpoint: '767px',
          numVisible: 1,
          numScroll: 1
      }
  ];
}
ngAfterViewInit() {
  if (isPlatformBrowser(this.platformId)) {
    console.log('test--',$(window).innerWidth);
  }
}
selectItem(index: number) {
  this.selectedItem = index;
}
transform(items:any[] , item:any): any {
  return items.indexOf(item);
}
}
