
<div class="main-margins">
    <div class="col-12 mt-25  ">
        <p-breadcrumb class="max-w-full" [model]="items">
            <ng-template pTemplate="item" let-item>
                <ng-container *ngIf="item.route; else elseBlock">
                    <a [routerLink]="item.route" class="p-menuitem-link">
                        <span [ngClass]="[item.icon ? item.icon : '', 'text-color']"></span>
                        <span class="text-color font-semibold">{{ item.label }}</span>
                    </a>
                </ng-container>
                <ng-template #elseBlock>
                    <a [href]="item.url">
                        <span class="text-color">{{ item.label }}</span>
                    </a>
                </ng-template>
            </ng-template>
        </p-breadcrumb>
        <div class="mt-4">
            <div class="d-grid">
                <span class="head">4 Star & Above Wedding Hotels in Gurgaon</span>
                <span class="sub-head">Showing 48 results as per your search criteria</span>
            </div>

        </div>
        <div class="mt-25  d-flex mb-3">
            <!-- <div class="card flex justify-content-center"> -->
               
            <!-- </div> -->
            <div class="col-3">
                <app-common-filter [filter1]="filter1"></app-common-filter>
            </div>
            <div class="col-9">
                <div class="image-radio-group">
                    <div *ngFor="let option of options" class="radio-option">
                      <!-- Radio Button -->
                      <input
                        type="radio"
                        name="image-radio"
                        [value]="option.value"
                        [(ngModel)]="selectedOption"
                        id="{{ option.value }}"
                      />
                      <!-- Label with Image -->
                      <label
                        for="{{ option.value }}"
                        class="radio-label"
                        [class.selected]="selectedOption === option.value"
                      >
                        <img [src]="option.image" alt="{{ option.label }}" />
                        <span class="label-text">{{ option.label }}</span>
                      </label>
                    </div>
                  </div>

                  <!-- ----------------------cards-------------------------- -->

                  <div class="parent-for1 col-md-12 ml-3 md:col-4" >
                    <div *ngFor="let venue of venues | filter:selectedCity" class=" d-inline-grid">
                
                <div class="border-1 surface-border border-round mr-3 mb-2">
                   
                    <div class="mb-3 d-inline-grid card-width">
                        <!-- <div class="card flex justify-content-center"> -->
                        <div class="relative mx-auto position-relative d-inline-flex venues topgrad">
                            <img 
                                src="{{ venue.img }}" 
                                [alt]="venue.property_name" 
                                class="w-full border-round d-block border-rad-top card-width " height="180"  (error)="venue.img = defaultImg" />
                                <!-- <div id="main-content"> -->
                                 
                                <!-- <i class="pi pi-heart-fill position-absolute t-0 " [ngStyle]="{'color' : (heartColor ?'red':'none')}" (click)="heartColor = !heartColor"></i> -->
                                <i class="pi pi-heart-fill position-absolute t-0 zIndex-99" [ngStyle]="{'color' : (heartColor ?'red':'none')}" (click)="heartColor = !heartColor"></i>
                                <img 
                                src="./assets/i-icon.svg"
                                class="position-absolute b-0 zIndex-99"  pTooltip="The Oberoi Amarvilas"
                                tooltipPosition="right" />
                                <p-button label="{{venue.area}}"  styleClass="city position-absolute"/>
                                
                           
                        </div>
                        <div class="dark-bottom col-md-12 d-flex w-100 ">
                            <div class="col-md-4"><img 
                                src="./assets/capacity.svg"
                                class="icons-venue"/>
                                <span class="venue-details">{{venue.pax}} </span></div>
                                
                            <div class="col-md-4 text-center"><img 
                                src="./assets/room.svg"
                                class="icons-venue "/>
                            <span class="venue-details">{{venue.rooms}} room</span></div>
                            <div class="col-md-4 text-left"><img 
                                src="./assets/banquet.svg"
                                class="icons-venue" />
                            <span class="venue-details">{{venue.banquest}} Banquests</span></div>
                        </div>
                    
                    </div>
                    <div class=" col-12">
                    <div class="card-name col-12">
                        <div class="card-title">
                            {{venue.property_name}} <img *ngIf="venue.blue_tick == 'yes'"
                            src="./assets/assured.svg"
                            class="icons-venue-2"/></div>
                       
                    </div>
                    <div class="col-12 d-flex mt-2">
                        <div class="card-sub-title col-8"> {{venue.property_type}}</div>
                       
                        <div class="card-sub-title ml-2 col-4" ><i class="pi pi-star-fill review-font " [ngStyle]="{'color' : '#F8BE4E'}" ></i> <span class="review-font">4.7 (15 reviews)</span></div>
                    </div>
                    <div class="col-12 d-flex mt-2">
                        <p-button label="Veg: {{venue.veg}}" styleClass="vendor-button" />
                        <p-button label="Non-veg:{{venue.nonVeg}}" styleClass="vendor-button" />
                    </div>
                </div>
                
                </div>
          
        </div>
        </div>
            </div>
        
    </div>
        <!-- <p>venue-list works!</p> -->
    </div>
  
</div>
