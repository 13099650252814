import * as i0 from '@angular/core';
import { Pipe, NgModule } from '@angular/core';
class NgxFilterPipe {
  /**
     * @param items object from array
     * @param term term's search
     * @param excludes array of strings which will ignored during search
     */
  transform(items, term, excludes = []) {
    if (!term || !items) return items;
    return NgxFilterPipe.filter(items, term, excludes);
  }
  /**
   *
   * @param items List of items to filter
   * @param term  a string term to compare with every property of the list
   * @param excludes List of keys which will be ignored during search
   *
   */
  static filter(items, term, excludes) {
    const toCompare = term.toLowerCase();
    function checkInside(item, term) {
      if (typeof item === "string" && item.toString().toLowerCase().includes(toCompare)) {
        return true;
      }
      for (let property in item) {
        if (item[property] === null || item[property] == undefined || excludes.includes(property)) {
          continue;
        }
        if (typeof item[property] === 'object') {
          if (checkInside(item[property], term)) {
            return true;
          }
        } else if (item[property].toString().toLowerCase().includes(toCompare)) {
          return true;
        }
      }
      return false;
    }
    return items.filter(function (item) {
      return checkInside(item, term);
    });
  }
  static {
    this.ɵfac = function NgxFilterPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxFilterPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "filter",
      type: NgxFilterPipe,
      pure: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxFilterPipe, [{
    type: Pipe,
    args: [{
      name: 'filter',
      pure: false
    }]
  }], null, null);
})();
class NgxSearchFilterModule {
  static {
    this.ɵfac = function NgxSearchFilterModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxSearchFilterModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxSearchFilterModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxSearchFilterModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxFilterPipe],
      imports: [],
      exports: [NgxFilterPipe]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-search-filter
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxFilterPipe, NgxSearchFilterModule };
