import { Component, OnInit } from '@angular/core';
import { SidebarModule } from 'primeng/sidebar';
import { MenuItem, MessageService } from 'primeng/api';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ToastModule } from 'primeng/toast';
import { AvatarModule } from 'primeng/avatar';
import { CardModule } from 'primeng/card';
@Component({
  selector: 'app-mobile-header',
  standalone: true,
  imports: [SidebarModule,PanelMenuModule, ToastModule, AvatarModule,CardModule],
  providers: [MessageService],
  templateUrl: './mobile-header.component.html',
  styleUrl: './mobile-header.component.scss'
})
export class MobileHeaderComponent implements OnInit {
  sidebarVisible2: boolean = false;
  items: MenuItem[] = [];

  constructor(private messageService: MessageService) {}

  ngOnInit() {
      this.items = [
          {
              label: 'Home',
              styleClass:'font-size',
              items: [
                  {
                      label: 'Banquet Hall',
                      icon: 'pi pi-arrow-up-right',
                      styleClass:'positionArrow',
                      command: () => {
                          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'File created', life: 3000 });
                      }
                  },
                  {
                      label: 'Marriage Garden & Hall',
                      icon: 'pi pi-arrow-up-right',
                      command: () => {
                          this.messageService.add({ severity: 'warn', summary: 'Search Results', detail: 'No results found', life: 3000 });
                      }
                  },
                  {
                      label: 'Destination',
                      icon: 'pi pi-arrow-up-right',
                      command: () => {
                          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                      }
                  },
                  {
                      label: 'Destination Wedding',
                      icon: 'pi pi-arrow-up-right',
                      command: () => {
                          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                      }
                  },
                  {
                      label: 'Wedding Resort',
                      icon: 'pi pi-arrow-up-right',
                      command: () => {
                          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                      }
                  },
                  {
                      label: 'Four/Five Star Hotels',
                      icon: 'pi pi-arrow-up-right',
                      command: () => {
                          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                      }
                  }
              ]
          },
          {
            label: 'Venue',
            items: [
                {
                    label: 'Banquet Hall',
                    icon: 'pi pi-arrow-up-right',
                    command: () => {
                        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'File created', life: 3000 });
                    }
                },
                {
                    label: 'Marriage Garden & Hall',
                    icon: 'pi pi-arrow-up-right',
                    command: () => {
                        this.messageService.add({ severity: 'warn', summary: 'Search Results', detail: 'No results found', life: 3000 });
                    }
                },
                {
                    label: 'Destination',
                    icon: 'pi pi-arrow-up-right',
                    command: () => {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                    }
                },
                {
                    label: 'Destination Wedding',
                    icon: 'pi pi-arrow-up-right',
                    command: () => {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                    }
                },
                {
                    label: 'Wedding Resort',
                    icon: 'pi pi-arrow-up-right',
                    command: () => {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                    }
                },
                {
                    label: 'Four/Five Star Hotels',
                    icon: 'pi pi-arrow-up-right',
                    command: () => {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                    }
                }
            ]
        },
        {
          label: 'Vendor',
          items: [
              {
                  label: 'Banquet Hall',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'File created', life: 3000 });
                  }
              },
              {
                  label: 'Marriage Garden & Hall',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'warn', summary: 'Search Results', detail: 'No results found', life: 3000 });
                  }
              },
              {
                  label: 'Destination',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              },
              {
                  label: 'Destination Wedding',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              },
              {
                  label: 'Wedding Resort',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              },
              {
                  label: 'Four/Five Star Hotels',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              }
          ]
      },
      
        {
          label: 'photos',
          items: [
              {
                  label: 'Banquet Hall',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'File created', life: 3000 });
                  }
              },
              {
                  label: 'Marriage Garden & Hall',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'warn', summary: 'Search Results', detail: 'No results found', life: 3000 });
                  }
              },
              {
                  label: 'Destination',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              },
              {
                  label: 'Destination Wedding',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              },
              {
                  label: 'Wedding Resort',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              },
              {
                  label: 'Four/Five Star Hotels',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              }
          ]
      },
      
        {
          label: 'Our Weddings',
          items: [
              {
                  label: 'Banquet Hall',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'File created', life: 3000 });
                  }
              },
              {
                  label: 'Marriage Garden & Hall',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'warn', summary: 'Search Results', detail: 'No results found', life: 3000 });
                  }
              },
              {
                  label: 'Destination',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              },
              {
                  label: 'Destination Wedding',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              },
              {
                  label: 'Wedding Resort',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              },
              {
                  label: 'Four/Five Star Hotels',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              }
          ]
      },
      
        {
          label: 'Blogs',
          items: [
              {
                  label: 'Banquet Hall',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'File created', life: 3000 });
                  }
              },
              {
                  label: 'Marriage Garden & Hall',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'warn', summary: 'Search Results', detail: 'No results found', life: 3000 });
                  }
              },
              {
                  label: 'Destination',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              },
              {
                  label: 'Destination Wedding',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              },
              {
                  label: 'Wedding Resort',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              },
              {
                  label: 'Four/Five Star Hotels',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              }
          ]
      },
      
        {
          label: 'Shop',
          items: [
              {
                  label: 'Banquet Hall',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'File created', life: 3000 });
                  }
              },
              {
                  label: 'Marriage Garden & Hall',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'warn', summary: 'Search Results', detail: 'No results found', life: 3000 });
                  }
              },
              {
                  label: 'Destination',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              },
              {
                  label: 'Destination Wedding',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              },
              {
                  label: 'Wedding Resort',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              },
              {
                  label: 'Four/Five Star Hotels',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              }
          ]
      },
      
        {
          label: 'E invites',
          items: [
              {
                  label: 'Banquet Hall',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'File created', life: 3000 });
                  }
              },
              {
                  label: 'Marriage Garden & Hall',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'warn', summary: 'Search Results', detail: 'No results found', life: 3000 });
                  }
              },
              {
                  label: 'Destination',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              },
              {
                  label: 'Destination Wedding',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              },
              {
                  label: 'Wedding Resort',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              },
              {
                  label: 'Four/Five Star Hotels',
                  icon: 'pi pi-arrow-up-right',
                  command: () => {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
                  }
              }
          ]
      },
          {
              label: 'Sign Out',
              icon: 'pi pi-sign-out',
              command: () => {
                  this.messageService.add({ severity: 'info', summary: 'Signed out', detail: 'User logged out', life: 3000 });
              }
          }
      ];
  }
}
