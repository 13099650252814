import { ChangeDetectorRef, Component, HostListener, inject, OnInit } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { MenuItem } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { BlockUIModule } from 'primeng/blockui';
import {MenubarModule} from 'primeng/menubar';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ComponentDataService } from '../component-data.service';
import { ActivatedRoute, Router } from '@angular/router';

interface City {
  name: string;
  code: string;
}
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [FormsModule,DropdownModule,BsDropdownModule,FontAwesomeModule,TabMenuModule,ButtonModule,CommonModule,BlockUIModule,MenubarModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  cities:[] | undefined;
  // navBar = ['Home','Venue','Vendor', ' photos','Our Weddings', 'Blogs', 'Shop', 'E Invites', 'Contact Us']

  navBar = [
    { label: 'Home', subMenu: [], route: '/home' },
    { label: 'Venue', subMenu: [{label:'Banquet Halls', route: '/venue/banquet'}, {label:'Marriage Gardens & Halls',route:'/venue/garden&halls'},{label:'Destination Weddings',route:'/venue/destinationWeddings'},{label:'Wedding Resorts',route:'/venue/weddingResorts'},{label:'4-Star Hotels',route:'/venue/4-star'},{label:'5-Star Hotels',route:'/venue/5-star'}], route: '/venues' },
    { label: 'Vendor', subMenu: [{label:'Bridal Makeup', route: '/vendor/makeup'}, {label:'Photography & Videography',route:'/vendor/photography'}, {label:'Wedding Planners',route:'/vendor/planners'},{label:'Decor & Floral Design',route:'/vendor/decor'},{label:'DJ & Sound',route:'/vendor/sound'},{label:'Dance & Choreography',route:'/vendor/choreography'},{label:'Entertainment & Performers',route:'/vendor/entertainment'},{label:'Food & Catering',route:'/vendor/catering'}], route: '/vendor' },
    { label: 'Gallery', subMenu: [], route: '/gallery' },
    { label: 'Our Weddings', subMenu: [], route: '/weddings' },
    // { label: 'Our Wedding', subMenu: ['Tips', 'Stories'] },
    { label: 'Blog', subMenu: [{label:'Bridal Makeup', route: 'blogs/makeup'}, {label:'Honeymoon & Travel', route: 'blogs/travel'},{label:'Wedding music', route: 'blogs/music'},{label:'Bridal hairstyles', route: 'blogs/hairstyle'},{label:'Wedding Decor', route: 'blogs/decor'},{label:'Bridal and Groom wear', route: 'blogs/cloths'},{label:'Mehandi designs', route: 'blogs/mehandi'},{label:'Wedding gifts and favours', route: 'blogs/gifts'},{label:'Wedding photography & videography', route: 'blogs/photography'}], route: '/blog' },
    { label: 'Shop', subMenu: [], route: '/shop' },
    { label: 'E Invites', subMenu: [], route: '/invite' },
    { label: 'Contact Us', subMenu: [], route: '/contact' },
  ];
  selectedItem: number | null = 0;
  selectedNav: number | null = 0;
  activeIndex: number | null = null;
  httpClient = inject(HttpClient);
faCoffee = faCoffee
  selectedCity = '';
  items: MenuItem[] | undefined;
  constructor(private ref: ChangeDetectorRef,private commonData:ComponentDataService,private router: Router,private route:ActivatedRoute) {
    console.log('route---',this.router.url)
    // this.selectedItem = this.navBar.findIndex(item => item.route === this.router.url);
  }
  ngOnInit() {

    this.items = [
      { label: 'Dashboard', icon: 'pi pi-home' },
      { label: 'Transactions', icon: 'pi pi-chart-line' },
      { label: 'Products', icon: 'pi pi-list' },
      { label: 'Messages', icon: 'pi pi-inbox' }
  ]
    if (localStorage.getItem("selectedCity") !== null) {
      this.selectedCity = localStorage.getItem('selectedCity') || '';
    }
    this.citysheetApi();
    this.setActiveIndexOnLoad()
}

// selectedItem: number | null = null;
private setActiveIndexOnLoad(): void {
  const currentRoute = this.router.url; // Get the current route
  this.activeIndex = this.navBar.findIndex((item) =>
    currentRoute.startsWith(item.route || '')
  );
}
navigateToSubMenu(subItem: any, event: MouseEvent): void {
  event.stopPropagation();
  if (subItem.route) {
    this.redirect(subItem.route);
  }
  this.selectedItem = null; // Close the dropdown after navigation
}
selectItem(index: number): void {
  this.selectedNav = this.selectedNav === index ? null : index;
}

hasDropdown(index: number): boolean {
  return this.navBar[index].subMenu.length > 0;
}
toggleDropdown(index: number, event: MouseEvent, item: any): void {
  // event.stopPropagation(); // Prevent click from propagating to document
  // if (item.subMenu.length > 0) {
  //   this.selectedItem = index;
    // If the item has a submenu, toggle its visibility
    // this.selectedItem = this.selectedItem === index ? null : index;
  // } else {
    // If no submenu, redirect to the associated route
    // this.selectedItem = this.selectedItem === index ? null : index;
    this.selectedItem = index;
    this.activeIndex = index;
    this.redirect(item.route);
  // }
  this.ref.detectChanges();
}


// Close dropdowns on click outside
@HostListener('document:click')
closeDropdown(): void {
  // this.selectedItem = null;
}
citysheetApi(){
  const sheetName ='cities';
  const url = `${environment.sheet_url}${environment.sheet_id}/values/${sheetName}?key=${environment.sheet_api_key}`;
  this.httpClient.get(url, {
     
  }).subscribe((response:any): void => {
    let arrr = response.values.map((x:any) =>({
      name:x[0],
      code:x[1]
    }))
     console.log('res---',arrr);
     this.cities = arrr;
     this.ref.detectChanges();
  }); 
  }
  citySelection(){
    console.log(this.selectedCity)
    localStorage.setItem('selectedCity',this.selectedCity);
    let cityName = this.cities?.filter(city => city['code'] == this.selectedCity)[0]['name'];
    console.log('cityName--',cityName)
    localStorage.setItem('cityName',cityName || '')
    this.commonData.changeMessage(cityName || '')

  }
  redirect(route: string): void {
    // event.stopPropagation(); // Prevent dropdown from closing prematurely
    this.router.navigate([route]);
    // this.selectedItem = null; // Close the dropdown after navigation
  }
  
}
