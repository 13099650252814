<div class="overflow-x-hidden">
    <p-galleria [value]="images" [indicatorsPosition]="'bottom'" [showIndicators]="true" [showThumbnails]="false"
        [showIndicatorsOnItem]="'true'" [responsiveOptions]="responsiveOptions" [changeItemOnIndicatorHover]="false"
        [containerStyle]="{'width': '100%'}">
        <ng-template pTemplate="item" let-item>
            <p-image src="{{item.itemImageSrc}}" alt="Image" styleClass="pImage" />


            <div class="search-icon zIndex-99 bottom-1 left-3">
                <img src="./assets/search.svg" height="20" alt="logo" class="position-relative margins zIndex-99">
            </div>
            <p-autoComplete [(ngModel)]="selectedCountry" [dropdown]="true" [suggestions]="filteredCountries"
                (completeMethod)="filterCountry($event)" optionLabel="name" class="position-absolute zIndex-99 bottom-1 right-1-5"
                placeholder="Find Vendors in Gurgaon">
                <ng-template></ng-template></p-autoComplete>

            <span class="position-absolute color-white font-weight-bold font-size-4 zIndex-99 top-10">Plan a Gurgaon Wedding!</span>

        </ng-template>
    </p-galleria>
    <div class="main-margins1">
    <div class="mt-25 ml-3  d-flex mb-3">
        <span class="search-font">Popular Venue Searches</span>
        <!-- <div class="ml-auto padd-right"> <p-button label="View all venues" styleClass="vendor-button" /></div> -->
    </div>
<!-- <p-carousel 
[value]="products" 
[numVisible]="1" 
[numScroll]="1" 
[circular]="false" 
[responsiveOptions]="responsiveOptions"> -->
<div class="parent-for ml-3" >
    <div *ngFor="let product of products">
        
        <div class="border-1 surface-border border-round mr-3">
           
            <div class="mb-3 d-inline-grid">
                <div class="relative mx-auto position-relative d-inline-flex">
                    <img 
                        src="{{ product.image }}" 
                        [alt]="product.name" 
                        class="w-full border-round d-block" height="180" width="312"/>
                       
                        <i class="pi pi-heart-fill position-absolute t-0 " [ngStyle]="{'color' : (heartColor ?'red':'none')}" (click)="heartColor = !heartColor"></i>
                        <img 
                        src="./assets/i-icon.svg"
                        class="position-absolute b-0"  pTooltip="The Oberoi Amarvilas"
                        tooltipPosition="right" />
                        <p-button label="{{product.city}}"  styleClass="city position-absolute"/>
                       
                </div>
                <div class="dark-bottom col-12 d-flex">
                    <div class="col-4"><img 
                        src="./assets/capacity.svg"
                        class="icons-venue"/>
                        <span class="venue-details">250-700pax</span></div>
                        
                    <div class="col-4 text-center"><img 
                        src="./assets/room.svg"
                        class="icons-venue "/>
                    <span class="venue-details">84 room</span></div>
                    <div class="col-4 text-left"><img 
                        src="./assets/banquet.svg"
                        class="icons-venue" />
                    <span class="venue-details">9 Banquests</span></div>
                </div>
            </div>
            <div class="d-flex">
            <div class="card-name">
                <div class="card-title">The Oberoi Amarvilas <img 
                    src="./assets/assured.svg"
                    class="icons-venue"/></div>
                <div class="card-sub-title">Four Star and above Hotels</div>
            </div>
            <div>
                <!-- <div class=""></div> -->
                <div class="card-sub-title ml-2"><i class="pi pi-star-fill  " [ngStyle]="{'color' : '#F8BE4E'}" ></i> <span class="review-font">4.7 (15 reviews)</span></div>
            </div>
        </div>
           
        </div>
    </div>
</div>
<!-- </p-carousel> -->
</div>
</div>