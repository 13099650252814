import * as i0 from '@angular/core';
import { Pipe, Injectable, NgModule } from '@angular/core';

/**
 * Created by vadimdez on 28/06/16.
 */
class FilterPipe {
  static isFoundOnWalking(value, key) {
    let walker = value;
    let found = false;
    do {
      if (walker.hasOwnProperty(key) || Object.getOwnPropertyDescriptor(walker, key)) {
        found = true;
        break;
      }
    } while (walker = Object.getPrototypeOf(walker));
    return found;
  }
  static isNumber(value) {
    return !isNaN(parseInt(value, 10)) && isFinite(value);
  }
  /**
   * Checks function's value if type is function otherwise same value
   */
  static getValue(value) {
    return typeof value === 'function' ? value() : value;
  }
  filterByString(filter) {
    if (filter) {
      filter = filter.toLowerCase();
    }
    return value => !filter || (value ? ('' + value).toLowerCase().indexOf(filter) !== -1 : false);
  }
  filterByBoolean(filter) {
    return value => Boolean(value) === filter;
  }
  filterByObject(filter) {
    return value => {
      for (const key in filter) {
        if (key === '$or') {
          if (!this.filterByOr(filter.$or)(FilterPipe.getValue(value))) {
            return false;
          }
          continue;
        }
        if (!value || !FilterPipe.isFoundOnWalking(value, key)) {
          return false;
        }
        if (!this.isMatching(filter[key], FilterPipe.getValue(value[key]))) {
          return false;
        }
      }
      return true;
    };
  }
  isMatching(filter, val) {
    switch (typeof filter) {
      case 'boolean':
        return this.filterByBoolean(filter)(val);
      case 'string':
        return this.filterByString(filter)(val);
      case 'object':
        return this.filterByObject(filter)(val);
    }
    return this.filterDefault(filter)(val);
  }
  /**
   * Filter value by $or
   */
  filterByOr(filter) {
    return value => {
      const length = filter.length;
      const arrayComparison = i => value.indexOf(filter[i]) !== -1;
      const otherComparison = i => this.isMatching(filter[i], value);
      const comparison = Array.isArray(value) ? arrayComparison : otherComparison;
      for (let i = 0; i < length; i++) {
        if (comparison(i)) {
          return true;
        }
      }
      return false;
    };
  }
  /**
   * Default filterDefault function
   */
  filterDefault(filter) {
    return value => filter === undefined || filter == value;
  }
  transform(array, filter) {
    if (!array) {
      return array;
    }
    switch (typeof filter) {
      case 'boolean':
        return array.filter(this.filterByBoolean(filter));
      case 'string':
        if (FilterPipe.isNumber(filter)) {
          return array.filter(this.filterDefault(filter));
        }
        return array.filter(this.filterByString(filter));
      case 'object':
        return array.filter(this.filterByObject(filter));
      case 'function':
        return array.filter(filter);
    }
    return array.filter(this.filterDefault(filter));
  }
  static ɵfac = function FilterPipe_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || FilterPipe)();
  };
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "filterBy",
    type: FilterPipe,
    pure: false
  });
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: FilterPipe,
    factory: FilterPipe.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FilterPipe, [{
    type: Pipe,
    args: [{
      name: 'filterBy',
      pure: false
    }]
  }, {
    type: Injectable
  }], null, null);
})();

/**
 * Created by vadimdez on 28/11/2016.
 */
class FilterPipeModule {
  static ɵfac = function FilterPipeModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || FilterPipeModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: FilterPipeModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [FilterPipe]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FilterPipeModule, [{
    type: NgModule,
    args: [{
      declarations: [FilterPipe],
      providers: [FilterPipe],
      exports: [FilterPipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FilterPipe, FilterPipeModule };
