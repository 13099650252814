<!-- <div > -->
<div class="filter-layout overflow-auto">
    <div class="margins-inside">
        <div class="col-12 mb-4"><img src="./assets/refine.svg" class="icons-venue" />
            <span class="ml-2"><span class="font-weight-bold">Refine</span> your search </span>
        </div>
        <p-iconField iconPosition="left">
            <p-inputIcon styleClass="pi pi-search" />
            <input type="text" pInputText placeholder="Search" />
        </p-iconField>

        <div class="mt-5">
            <div class="col-12 mb-3">
                <span class="ml-2"> Type of Venue </span>
            </div>

            <div class="button-container">
                <button *ngFor="let option of filter1" (click)="toggleOption(option)"
                    [class.active]="isSelected(option)" class="button">
                    {{ option }}
                </button>
            </div>
        </div>
        <p-divider />
        <div class="slider-container">
            <div class="col-12 mb-3">
                <span class="ml-2"> Type of Venue </span>
            </div>
            <!-- Histogram Background -->
            <div class="histogram">
              <div
                *ngFor="let bar of histogramData; let i = index"
                class="bar"
                [style.height]="bar + 'px'"
                [class.selected]="i >= selectedMinIndex && i <= selectedMaxIndex"
              ></div>
            </div>
          
            <!-- Dual Range Slider -->
            <ngx-slider
              [(value)]="selectedMin"
              [(highValue)]="selectedMax"
              [options]="sliderOptions"
            ></ngx-slider>
          
            <!-- Minimum and Maximum Labels -->
            <div class="values">
                <div class="value-container">
                    <div class="label">Minimum</div>
                    <div class="value">₹{{ selectedMin }}</div>
                  </div>
              <!-- <div class=""><span class="ml-2"> Type of Venue </span> <br/><span>₹{{ selectedMin }}</span></div> -->
              <!-- <div class="">Maximum: ₹{{ selectedMax }}</div> -->
              <div class="value-container">
                <div class="label">Maximum</div>
                <div class="value">₹{{ selectedMax }}</div>
              </div>
            </div>
          </div>
    </div>
</div>
<!-- </div> -->